import {
  createTemplate,
  templates,
  updateTemplate,
  deleteTemplate,
  sendMails,
} from "../../store/reduxToolkit/reducers/emailReducer"

export const sendEmails = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(sendMails(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const newTemplate = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(createTemplate(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const getTemplates = async (dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(templates()).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const templateUpdate = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(updateTemplate(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const templateDelete = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(deleteTemplate(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}
