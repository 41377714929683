import React, { useState } from "react"
import { toast } from "react-toastify"
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap"
import { useDispatch } from "react-redux"
import { editLesson, editLoomLesson } from "helpers/helpers/lesson"
import ButtonLoader from "components/Loader/ButtonLoader"

const EditLesson = ({ modal_xlarge, tog_xlarge, action, lessonId }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [lessonTitle, setLessonTitle] = useState("")
  const [videoFile, setVideoFile] = useState(null)
  const [duration, setDuration] = useState(0)
  const [loomLink, setLoomLink] = useState("")

  const handleLessonCreation = async () => {
    if (action?.lesson?.loomType) {
      const data = {
        id: lessonId,
        title: lessonTitle,
        loomLink,
        duration,
      }
      const response = await editLoomLesson(data, dispatch, setLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
      } else {
        toast.success(response.message.payload.message)
        tog_xlarge()
      }
    } else {
      let formData = new FormData()
      formData.append("title", lessonTitle)
      formData.append("lessonVideo", videoFile)
      formData.append("duration", duration)
      const data = {
        id: lessonId,
        formData,
      }
      const response = await editLesson(data, dispatch, setLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
      } else {
        toast.success(response.message.payload.message)
        tog_xlarge()
        window.location.reload()
      }
    }
  }

  return (
    <div>
      <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="sl">
        <ModalHeader className="mt-0" toggle={tog_xlarge}>
          {action?.action} Lesson
        </ModalHeader>
        <ModalBody>
          <div className="p-3">
            <input
              type="text"
              placeholder="Lesson Title"
              className="form-control"
              onChange={e => setLessonTitle(e.target.value)}
            />
            <br />
            {action?.lesson?.loomType ? (
              <input
                type="text"
                placeholder="Loom video link *"
                className="form-control"
                onChange={e => setLoomLink(e.target.value)}
              />
            ) : (
              <>
                <p className="h6">Lesson video: *</p>
                <input
                  type="file"
                  accept="video/*"
                  placeholder="Video file"
                  className="form-control"
                  onChange={e => setVideoFile(e.target.files[0])}
                />
              </>
            )}
            <br />
            <input
              type="number"
              placeholder="Duration in hours"
              className="form-control"
              onChange={e => setDuration(e.target.value)}
            />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {loading ? (
                <Button color="primary">
                  <ButtonLoader size={30} color1={"white"} color2={"white"} />
                </Button>
              ) : (
                <Button color="primary" onClick={handleLessonCreation}>
                  {action?.action}
                </Button>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default EditLesson
