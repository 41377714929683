import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit"
import axios from "axios"

const url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/mails`
const userToken = localStorage.getItem("deonicode-admin")

const initialState = {
  subscribers: [],
  subscriber: null,
}

export const subscribers = createAsyncThunk(
  "subscriber/subscribers",
  async thunkAPI => {
    try {
      const response = await axios.get(`${url}/subscribers`,{
        headers:{
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const subscriberSlice = createSlice({
  name: "subscriber",
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(subscribers.fulfilled, (state, action) => {
      state.subscribers = action.payload
    })
  },
})

export const {} = subscriberSlice.actions

export default subscriberSlice.reducer
