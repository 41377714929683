import {
  create,
  loomLesson,
  chapterLessons,
  deleteLesson,
  update,
  updateLoomLesson,
  deleteLoomLesson,
} from "../../store/reduxToolkit/reducers/lessonReducer"

export const createLesson = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(create(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const createLoomLesson = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(loomLesson(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const singleChapterLessons = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(chapterLessons(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const editLesson = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(update(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const editLoomLesson = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(updateLoomLesson(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const singleLessonDelete = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(deleteLesson(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const singleLoomLessonDelete = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(deleteLoomLesson(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}
