import { useContext } from "react"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Label,
  Form,
  FormFeedback,
  Input,
} from "reactstrap"

// Redux
import { connect, useSelector, useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
// import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginAdmin } from "helpers/helpers/authentication"
import { AuthContext } from "context/authContext/authContext"

// import images
import logoSm from "../../assets/images/logo-icon.png"

// Loader Component
import Loader from "components/Loader/PageLoader"
import ButtonLoader from "components/Loader/ButtonLoader"

const Login = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [userLogin, setUserLogin] = useState([])
  const { email } = useContext(AuthContext)

  const { user } = useSelector(state => ({
    user: state.auth.user,
  }))

  useEffect(() => {
    setPageLoading(true)
    let timer = setTimeout(() => setPageLoading(false), 3000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    if (user && user) {
      setUserLogin({
        email: email,
      })
    }
    if (localStorage.getItem("deonicode-admin")) {
      navigate("/dashboard", { replace: true })
    }
  }, [user])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed //
    enableReinitialize: true,

    initialValues: {
      email: userLogin.email || "",
      password: userLogin.password || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async values => {
      const response = await loginAdmin(values, dispatch, setLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
        return
      } else {
        toast.success(response.message.payload.message)
        return
      }
    },
  })

  document.title = "Login | Deonicode - Admin Login"
  if (pageLoading) {
    return <Loader />
  }
  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div> */}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">Welcome Back !</h5>
                    <p className="text-white-50">Deonicode</p>
                    <Link to="/" className="logo logo-admin">
                      <img src={logoSm} height="50" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <Form
                      className="mt-4"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                      action="#"
                    >
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="username">
                          Email
                        </Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter Email"
                          type="email"
                          id="username"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="userpassword">
                          Password
                        </Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          className="form-control"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 row">
                        <div className="col-sm-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-6 text-end">
                          {loading ? (
                            <button className="btn btn-primary w-md waves-effect waves-light">
                              <ButtonLoader
                                size={30}
                                color1={"#ffffff"}
                                color2={"#ffffff"}
                              />
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Log In
                            </button>
                          )}
                        </div>
                      </div>

                      {/* <div className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                          <Link to="/forgot-password">
                            <i className="mdi mdi-lock"></i> Forgot your
                            password?
                          </Link>
                        </div>
                      </div> */}
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Login

// const mapStateToProps = state => {
//   const { error } = state.Login
//   return { error }
// }

// export default withRouter(
//   connect(mapStateToProps, { loginUser, apiError })(Login)
// )

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginAdmin: PropTypes.func,
}
