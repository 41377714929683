import React from "react"
import AtomLoader from "react-loaders-kit/lib/atom/AtomLoader" // Recommended to reduce bundle size

const Loader = () => {
  const loaderProps = {
    loading: true,
    size: 150,
    duration: 1,
    colors: ["#f1734f", "#f6b93b"],
  }

  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <AtomLoader {...loaderProps} />
    </div>
  )
}

export default Loader
