import {
  create,
  getAllCourses,
  instructorCourses,
  readOne,
  update,
  updateStatus,
} from "../../store/reduxToolkit/reducers/courseReducer"

export const createCourse = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(create(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const allCourses = async (dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(getAllCourses()).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const myCourses = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(instructorCourses(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const singleCourse = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(readOne(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const editCourse = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(update(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const approveCourse = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(updateStatus(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}
