import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Row, Col, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch } from "react-redux"
import StatusModal from "components/StatusModal"
import { getAllTeachers } from "helpers/helpers/Teachers"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const Teachers = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [modal_status, setstatus_approve] = useState(false)
  const [statusItem, setStatusItem] = useState("")
  const [teachers, setTeachers] = useState([])

  const tog_approve = () => {
    setstatus_approve(!modal_status)
  }

  const statusUpdateHandler = item => {
    setStatusItem(item)
    tog_approve()
  }

  const allTeachers = async () => {
    const response = await getAllTeachers(dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    } else {
      setTeachers(response.message.payload.users)
      toast.success(response.message.payload.message)
    }
  }

  useEffect(() => {
    allTeachers()
  }, [])

  function filterFunction(textInput) {
    // Declare variables
    var input, filter, table, tr, td, i, txtValue
    input = textInput
    filter = input.value.toUpperCase()
    table = document.getElementById("tutorTable")
    tr = table.getElementsByTagName("tr")

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[0]
      if (td) {
        txtValue = td.textContent || td.innerText
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = ""
        } else {
          tr[i].style.display = "none"
        }
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Deonicode" breadcrumbItem="Tutors" />
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <CardTitle className="mb-4">Tutors</CardTitle>
                </Col>
                <Col xl={2}>
                  <CardTitle className="mb-4">
                    <input
                      type="text"
                      placeholder="search tutor"
                      className="form-control"
                      onChange={e => filterFunction(e.target)}
                    />
                  </CardTitle>
                </Col>
              </Row>
              <div className="table-responsive">
                <table
                  className="table align-middle table-nowrap mb-0"
                  id="tutorTable"
                >
                  <thead className="table-light">
                    <tr>
                      <th className="align-middle">ID</th>
                      <th className="align-middle">Name</th>
                      <th className="align-middle">Email</th>
                      <th className="align-middle">Phone</th>
                      <th className="align-middle">Age</th>
                      <th className="align-middle">Category</th>
                      <th className="align-middle">Occupation</th>
                      <th className="align-middle">Status</th>
                      <th className="align-middle">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={9}>
                          <ButtonLoader
                            size={40}
                            color1={"black"}
                            color2={"black"}
                          />
                        </td>
                      </tr>
                    ) : teachers.length > 0 ? (
                      teachers.map((teacher, key) => (
                        <tr key={"_tr_" + key}>
                          <td>
                            <div className="font-size-16">{key + 1}</div>
                          </td>
                          <td>
                            <Link to="#" className="text-body fw-bold">
                              {teacher?.username}
                            </Link>
                          </td>

                          <td>{teacher?.email}</td>
                          <td>{teacher?.phoneNumber}</td>
                          <td>{teacher?.age}</td>
                          <td> {teacher?.targetSubject}</td>
                          <td>{teacher?.occupation}</td>
                          <td>
                            {teacher?.status === "pending" ? (
                              <span className="bg-warning text-white bold p-1 rounded">
                                Pending
                              </span>
                            ) : teacher?.status === "active" ? (
                              <span className="bg-success text-white bold p-1 rounded">
                                Active
                              </span>
                            ) : teacher?.status === "suspended" ? (
                              <span className="bg-dark text-white bold p-1 rounded">
                                Suspended
                              </span>
                            ) : (
                              <span className="bg-danger text-white bold p-1 rounded">
                                Deactivated
                              </span>
                            )}
                          </td>

                          {/* <td>
                          <Button
                            type="button"
                            color="primary"
                            size="sm"
                            className="btn-rounded waves-effect waves-light"
                          >
                            View
                          </Button>
                        </td> */}
                          <td>
                            <Link to={`/teacher-details/${teacher._id}`}>
                              <Button color="primary m-1" size="sm">
                                View
                              </Button>
                            </Link>
                            <Button
                              size="sm"
                              color="success"
                              onClick={() =>
                                statusUpdateHandler({
                                  type: "instructor",
                                  text: teacher.username,
                                  id: teacher._id,
                                })
                              }
                            >
                              Edit
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <div className="text-center">No Instructor Found</div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </div>
        <StatusModal
          modal_xlarge={modal_status}
          tog_xlarge={tog_approve}
          action={statusItem}
        />
      </div>
    </React.Fragment>
  )
}

export default Teachers
