import classNames from "classnames"
import Delete from "components/Delete"
import React, { useState, useEffect, useContext } from "react"
import {
  Button,
  Col,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"
import CreateChapter from "./CreateChapter"
import CreateLesson from "./CreateLesson"
import EditLesson from "./EditLesson"
import { useDispatch } from "react-redux"
import { AuthContext } from "context/authContext/authContext"
import { singleChapterLessons } from "helpers/helpers/lesson"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const Lesson = ({ chapter }) => {
  const { role } = useContext(AuthContext)
  const [singlebtn, setSinglebtn] = useState(false)
  const [modal_chapter, setmodal_chapter] = useState(false)
  const [modal_update_chapter, setmodal_update_chapter] = useState("")
  const [modal_lesson, setmodal_lesson] = useState(false)
  const [modal_edit_lesson, setmodal_edit_lesson] = useState(false)
  const [modal_delete, setmodal_delete] = useState(false)
  const [action, setAction] = useState(false)
  const [deleteItem, setDeleteItem] = useState(false)
  const [open, setOpen] = useState()
  //
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [chapterId, setChapterId] = useState("")
  const [lessonId, setLessonId] = useState("")
  const [lessons, setLessons] = useState([])

  const tog_chapter = () => {
    setmodal_chapter(!modal_chapter)
  }

  const handleUpdateChapter = item => {
    setmodal_update_chapter(item)
    tog_chapter()
  }
  const tog_lesson = () => {
    setmodal_lesson(!modal_lesson)
  }

  const tog_edit_lesson = () => {
    setmodal_edit_lesson(!modal_edit_lesson)
  }
  const tog_delete = () => {
    setmodal_delete(!modal_delete)
  }

  const handleCreateLesson = _action => {
    setAction(_action)
    tog_lesson()
  }

  const handleEditLesson = _action => {
    setAction(_action)
    tog_edit_lesson()
  }

  const handleDelete = item => {
    setDeleteItem(item)
    tog_delete()
  }

  const myChapterLessons = async () => {
    if (chapter) {
      const data = {
        id: chapter._id,
      }
      const response = await singleChapterLessons(data, dispatch, setLoading)
      if (response.status === "error") {
        return
      } else {
        setLessons([...response.message.payload?.lessons].reverse())
      }
    }
  }

  useEffect(() => {
    myChapterLessons()
  }, [chapter])

  return (
    <div className="accordion" id="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className={classNames(
              {
                collapsed: open === false,
              },
              "accordion-button fw-semibold"
            )}
            type="button"
            onClick={() => {
              setOpen(!open)
            }}
            style={{ cursor: "pointer" }}
          >
            {chapter?.title}
          </button>
        </h2>

        <Collapse isOpen={open} className="accordion-collapse">
          <div className="accordion-body">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Dropdown
                isOpen={singlebtn}
                toggle={() => setSinglebtn(!singlebtn)}
              >
                <DropdownToggle tag="button" className="btn btn-primary">
                  Options <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => (
                      setChapterId(chapter._id), handleCreateLesson("Create")
                    )}
                  >
                    Create Lesson
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      handleUpdateChapter({
                        type: "Update",
                        id: chapter._id,
                      })
                    }
                  >
                    Edit Chapter
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      handleDelete({
                        type: "chapter",
                        text:
                          chapter.title +
                          " This will deletes all lessons under " +
                          chapter.title,
                        id: chapter._id,
                      })
                    }
                  >
                    Delete Chapter
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            {loading ? (
              <ButtonLoader size={40} color1={"black"} color2={"black"} />
            ) : lessons.length > 0 ? (
              lessons?.map((lesson, index) => (
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  key={index}
                >
                  <div className="d-flex">
                    {lesson.loomLink ? (
                      <div
                        style={{
                          maxWidth: "200px",
                          height: "100px",
                        }}
                      >
                        <iframe
                          src={lesson.loomLink.replace("share", "embed")}
                          frameBorder="0"
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          allowFullScreen
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        ></iframe>
                      </div>
                    ) : (
                      <video
                        style={{
                          maxWidth: "150px",
                        }}
                        controls
                      >
                        <source src={lesson?.content?.doc} />
                      </video>
                    )}

                    <p
                      className="mt-3 h4"
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      {lesson?.title}
                    </p>
                  </div>
                  {role !== "admin" ? (
                    <div className="mt-3">
                      <Button
                        onClick={() => (
                          setLessonId(lesson._id), handleEditLesson("Update")
                        )}
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={() =>
                          handleDelete({
                            type: "lesson",
                            text: lesson.title,
                            id: lesson._id,
                          })
                        }
                        color="danger"
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))
            ) : (
              "No lessons found"
            )}
          </div>
        </Collapse>
      </div>
      <CreateChapter
        modal_xlarge={modal_chapter}
        tog_xlarge={tog_chapter}
        action={modal_update_chapter}
      />
      <CreateLesson
        modal_xlarge={modal_lesson}
        tog_xlarge={tog_lesson}
        action={action}
        chapterId={chapterId}
      />
      <EditLesson
        modal_xlarge={modal_edit_lesson}
        tog_xlarge={tog_edit_lesson}
        action={action}
        lessonId={lessonId}
      />
      <Delete
        modal_xlarge={modal_delete}
        tog_xlarge={tog_delete}
        action={deleteItem}
      />
    </div>
  )
}

export default Lesson
