import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import { toast } from "react-toastify"
import { useParams } from "react-router-dom"
// import { EditorState, convertFromRaw, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import moment from "moment"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch } from "react-redux"
import { singleCourse } from "helpers/helpers/courses"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const CourseDetails = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [course, setCourse] = useState(null)
  const [desc, setDesc] = useState("")
  const { id } = useParams()

  const courseDetails = async () => {
    const data = {
      id,
    }
    const response = await singleCourse(data, dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    } else {
      setCourse(response.message.payload.course)
    }
  }

  useEffect(() => {
    if (course !== null) {
      setDesc(JSON.parse(course?.desc))
    }
  }, [course])

  useEffect(() => {
    courseDetails()
  }, [id])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            // maintitle="Deonicode"
            title="Deonicode"
            breadcrumbItem="Course Details"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {loading ? (
                    <div>
                      <ButtonLoader
                        size={40}
                        color1={"black"}
                        color2={"black"}
                      />
                    </div>
                  ) : (
                    <div>
                      <Row>
                        <Col xl={4} lg={4} sm={12}>
                          <img
                            className="rounded"
                            src={course?.coverImage?.doc}
                            alt="User Avatar"
                            width="100%"
                            height="300"
                          />
                        </Col>
                        <Col>
                          <div className="mt-2">
                            <span className="fw-bold">Course Title: </span>
                            {course?.title}
                          </div>
                          <div className="fw-bold mt-2">
                            <a href={course?.introVideoUrl} target="_blank">
                              Introductory video
                            </a>
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Course Level: </span>{" "}
                            {course?.courseLevel}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Category: </span>{" "}
                            {course?.category}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Duration: </span>
                            {course?.duration} hours
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Language: </span>{" "}
                            {course?.language}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Price: </span>$
                            {course?.price}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Sale Count: </span>{" "}
                            {course?.saleCount}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Status: </span>
                            {course?.isApproved ? (
                              <span className="p-2 text-success fw-bold">
                                Approved
                              </span>
                            ) : (
                              <span className="p-2 text-warning fw-bold">
                                Pending
                              </span>
                            )}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Tags: </span>
                            {course?.tags?.map((tag, idx) => (
                              <span className="m-1" key={idx}>
                                {tag}
                              </span>
                            ))}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Created on: </span>{" "}
                            {moment(course?.createdAt).format(
                              "DD-MM-YYYY HH:mm"
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mt-4">
                          <div className="fw-bold">Course Description: </div>
                          <div
                            className="mt-4"
                            dangerouslySetInnerHTML={{ __html: desc }}
                          />
                          {/* <div>{desc}</div> */}
                        </Col>
                      </Row>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CourseDetails
