import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/transaction`
const userToken = localStorage.getItem("deonicode-admin")

const initialState = {
  transactions: [],
  transaction: null,
}

export const getTransactions = createAsyncThunk(
  "transaction/getTransactions",
  async thunkAPI => {
    try {
      const response = await axios.get(`${url}/transactions`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getInstructorTransactions = createAsyncThunk(
  "transaction/getInstructorTransactions",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`${url}/transactions/${data.id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getConversionRate = createAsyncThunk(
  "transaction/getConversionRate",
  async thunkAPI => {
    try {
      const response = await axios.get(`${url}/currency-conversion`)
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload.transactions
      })
      .addCase(getInstructorTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload.transactions
      })
  },
})

export const { } = transactionSlice.actions

export default transactionSlice.reducer
