import {
  getUsers,
  getUser,
  updateUserStatus,
} from "../../store/reduxToolkit/reducers/usersReducer"

export const getAllUsers = async (dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(getUsers()).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const getSingleUser = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(getUser(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const updateStudentStatus = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(updateUserStatus(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}
