import {
  createDiscount,
  discount,
  updateDiscount,
  deleteDiscount,
  //
  priceRange,
  createPriceRange,
  //
  updatePercentage,
  deonicodePercentage,
  // 
  affiliatePercentage,
  updateAffiliatePercentage
} from "../../store/reduxToolkit/reducers/settingsReducer"

export const newDiscount = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(createDiscount(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const getDiscount = async (dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(discount()).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const discountUpdate = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(updateDiscount(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const discountDelete = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(deleteDiscount(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

// PRICE RANGE HELPERS

export const newPriceRange = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(createPriceRange(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const getPriceRange = async (dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(priceRange()).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

// DEONICODE PERCENTAGE HELPERS

export const newPercentage = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(updatePercentage(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const getPercentage = async (dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(deonicodePercentage()).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

// DEONICODE AFFILIATE PERCENTAGE HELPERS

export const newAffiliatePercentage = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(updateAffiliatePercentage(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const getAffiliatePercentage = async (dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(affiliatePercentage()).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}