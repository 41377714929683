import React, { useState } from "react"
import { Col, Modal, ModalBody, ModalHeader, Row, Button } from "reactstrap"
import { approveCourse } from "helpers/helpers/courses"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"

const ModalComponent = ({ modal_xlarge, tog_xlarge, action }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const functionHandle = async () => {
    const data = {
      id: action.id,
    }
    if (action.type === "approveCourse") {
      const response = await approveCourse(data, dispatch, setLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
        return
      } else {
        toast.success(response.message.payload.message)
        tog_xlarge()
        window.location.reload()
        return
      }
    }
  }

  return (
    <div>
      <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="sl">
        <ModalHeader className="mt-0" toggle={tog_xlarge}>
          {action.type === "approveCourse" ? "Approve course" : ""}
        </ModalHeader>
        <ModalBody>
          <div className="p-3">
            <p className="text-center">
              Are you sure you want to {action.text}?
            </p>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button color="success" onClick={functionHandle}>
                {loading ? "Loading..." : "Proceed"}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ModalComponent
