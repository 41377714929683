import React, { useEffect, useState, useContext } from "react"
import { Button } from "reactstrap"
import { Editor } from "primereact/editor"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import {
  mentorshipQuestions,
  postQuestionResponse,
} from "helpers/helpers/mentorship"
import { AuthContext } from "context/authContext/authContext"
import placeholderImage from "../../assets/images/users/placeholder.jpg"
import draftToHtml from "draftjs-to-html"
import { format } from "timeago.js"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const Questions = ({ user, courseId }) => {
  const dispatch = useDispatch()
  const { userId } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [chatLoading, setChatLoading] = useState(false)
  const [questionResponse, setQuestionResponse] = useState("")
  const questions = useSelector(state => state.mentorship.questions)

  const getQuestons = async () => {
    const data = {
      userId: user._id,
      courseId,
    }
    await mentorshipQuestions(data, dispatch, setChatLoading)
  }

  const handleQuestionResponse = async () => {
    if (questionResponse) {
      const data = {
        senderId: userId,
        receiverId: user._id,
        courseId,
        message: JSON.stringify(questionResponse),
      }
      const response = await postQuestionResponse(data, dispatch, setLoading)
      if (response.status === "error") {
        toast.error(response.message)
      } else {
        toast.success(response.message.payload.message)
      }
    } else {
      toast.error("Message cannot be empty")
    }
  }

  useEffect(() => {
    if (user && courseId) {
      getQuestons()
    }
  }, [user, courseId])

  return (
    <div className="chat-box">
      <div className="chat-header">
        <div className="current-mentee">
          <img
            src={(user && user.avatar.doc) || placeholderImage}
            height={55}
            width={55}
            className="mentee-img"
          />
          <p>{(user && user.username) || "Select user"}</p>
        </div>
      </div>
      <div className="chats">
        {chatLoading ? (
          <ButtonLoader size={40} color1={"black"} color2={"black"} />
        ) : questions && questions.length > 0 ? (
          questions.map((item, index) => {
            return (
              <div className="chat-txt" key={index}>
                <div className="chat-msg-1">
                  <img
                    src={
                      item.users[0] === userId
                        ? "/favicon.png"
                        : user?.avatar?.doc
                    }
                    height={35}
                    width={35}
                    className="chat-img rounded-circle"
                  />
                  <div className="chat-msg">
                    <div className="d-flex">
                      <p className="h6">
                        {item.users[0] === userId
                          ? "Instructor"
                          : user?.username}
                      </p>
                      {/* <div className="chat-actions">
                        <div>
                          <i className="fa fa-trash"></i>
                        </div>
                        <div>
                          <i className="fa fa-edit"></i>
                        </div>
                      </div> */}
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: draftToHtml(JSON.parse(item?.message)),
                      }}
                    />
                    <div>{format(item?.createdAt)}</div>
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div className="text-center mt-2 fw-bold">
            <p>Select user to load messages</p>
          </div>
        )}
      </div>
      {user === null ? (
        ""
      ) : (
        <>
          <div className="chat-footer">
          <Editor onTextChange={(e) => setQuestionResponse(e.htmlValue)} style={{ height: '320px' }} />
          </div>
          <div className="send-btn">
            {loading ? (
              <Button color="primary">
                <ButtonLoader size={30} color1={"white"} color2={"white"} />
              </Button>
            ) : (
              <Button color="primary" onClick={handleQuestionResponse}>
                {"Send"}
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default Questions
