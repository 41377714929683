import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/instructor`
const userToken = localStorage.getItem("deonicode-admin")

const initialState = {
  teachers: [],
  teacher: null,
}

export const getTeachers = createAsyncThunk(
  "teachers/getTeachers",
  async thunkAPI => {
    try {
      const response = await axios.get(`${url}/instructors`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getTeacher = createAsyncThunk(
  "teachers/getTeacher",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`${url}/instructor/${data.id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const uploadProfileImage = createAsyncThunk(
  "teachers/uploadProfileImage",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${url}/upload-profile-image/${data.id}`,
        data.formData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateTeacherStatus = createAsyncThunk(
  "teachers/updateTeacherStatus",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${url}/update-instructor-status/${data.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateDetails = createAsyncThunk(
  "teachers/updateDetails",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${url}/update-user/${data.id}`,
        data.values,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      if (response.data) {
        localStorage.setItem(
          "deonicode-admin",
          JSON.stringify(response.data.token)
        )
      }
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateSocialDetails = createAsyncThunk(
  "teachers/updateSocialDetails",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${url}/update-social-details/${data.id}`,
        data.values,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      if (response.data) {
        localStorage.setItem(
          "deonicode-admin",
          JSON.stringify(response.data.token)
        )
      }
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updatePassword = createAsyncThunk(
  "teachers/updatePassword",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${url}/update-password/${data.id}`,
        data.values,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const generateAffiliateLink = createAsyncThunk(
  "teachers/generateAffiliateLink",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${url}/referral-link`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      });
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const teachersSlice = createSlice({
  name: "teachers",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTeachers.fulfilled, (state, action) => {
        state.teachers = action.payload
      })
      .addCase(getTeacher.fulfilled, (state, action) => {
        state.teacher = action.payload
      })
  },
})

export const { logout } = teachersSlice.actions

export default teachersSlice.reducer
