import React, { useState } from "react"
import { Modal, ModalBody, ModalHeader, Button, Input } from "reactstrap"
import Select from "react-select"
import { updateInstructorStatus } from "helpers/helpers/Teachers"
import { updateStudentStatus } from "helpers/helpers/users"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const StatusModal = ({ modal_xlarge, tog_xlarge, action }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(false)
  const [reason, setReason] = useState("")

  const selectOptions = [
    {
      label: "User Status",
      options: [
        { label: "Active", value: "active" },
        { label: "Suspended", value: "suspended" },
        { label: "Deactivated", value: "deactivated" },
      ],
    },
  ]

  const statusHandler = async () => {
    if (status && reason && action.id) {
      const data = {
        id: action.id,
        status,
        reason,
      }
      if (action.type === "user") {
        const response = await updateStudentStatus(data, dispatch, setLoading)
        if (response.status === "error") {
          toast.error(response.res.payload)
          return
        } else {
          toast.success(response.message.payload.message)
          tog_xlarge()
          window.location.reload()
          return
        }
      }
      if (action.type === "instructor") {
        const response = await updateInstructorStatus(
          data,
          dispatch,
          setLoading
        )
        if (response.status === "error") {
          toast.error(response.res.payload)
          return
        } else {
          toast.success(response.message.payload.message)
          tog_xlarge()
          window.location.reload()
          return
        }
      }
    } else {
      toast.error("All fields are required")
      return
    }
  }

  return (
    <div>
      <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="sl">
        <ModalHeader className="mt-0" toggle={tog_xlarge}>
          Update {action.text} Status
        </ModalHeader>
        <ModalBody>
          <div className="p-3">
            <div>
              <Select
                onChange={e => setStatus(e.value)}
                options={selectOptions}
                classNamePrefix="select2-selection"
              />
            </div>
            <br />
            <div>
              <Input
                name="reason"
                className="form-control"
                placeholder="Enter reason"
                type="textarea"
                onChange={e => setReason(e.target.value)}
              />
            </div>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {loading ? (
                <Button color="success">
                  <ButtonLoader size={30} color1={"white"} color2={"white"} />
                </Button>
              ) : (
                <Button color="success" onClick={statusHandler}>
                  {"Proceed"}
                </Button>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default StatusModal
