import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/user`
const userToken = localStorage.getItem("deonicode-admin")

const initialState = {
  users: [],
  user: null,
}

export const getUsers = createAsyncThunk("user/getUsers", async thunkAPI => {
  try {
    const response = await axios.get(`${url}/users`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      }
    })
    return response.data
  } catch (error) {
    const message =
      (error.message && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const getUser = createAsyncThunk(
  "user/getUser",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`${url}/user/${data.id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateUserStatus = createAsyncThunk(
  "user/updateUserStatus",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${url}/update-user-status/${data.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.user = action.payload
      })
  },
})

export const { logout } = userSlice.actions

export default userSlice.reducer
