import {
  create,
  courseChapters,
  deleteChapter,
  update,
} from "../../store/reduxToolkit/reducers/chapterReducer"

export const createChapter = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(create(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const myChapters = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(courseChapters(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const updateChapter = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(update(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const singleChapterDelete = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(deleteChapter(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}
