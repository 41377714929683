import React, { useState, useEffect, useContext } from "react"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import { toast } from "react-toastify"
// import { Link } from "react-router-dom"
import moment from "moment"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import CreateCategory from "components/category/CreateCategory"
import { AuthContext } from "context/authContext/authContext"
import { useDispatch, useSelector } from "react-redux"
import { allCategories } from "helpers/helpers/categories"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const Category = () => {
  const dispatch = useDispatch()
  const { role } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [modal_edit, setmodal_edit] = useState(false)
  const [editItem, setEditItem] = useState("")
  const [categories, setCategories] = useState([])
  const stateCategories = useSelector(
    state => state.category.categories.categories
  )

  const tog_edit = () => {
    setmodal_edit(!modal_edit)
  }

  const statusUpdateHandler = item => {
    setEditItem(item)
    tog_edit()
  }

  const getAllCategories = async () => {
    const response = await allCategories(dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    } else {
      toast.success(response.message.payload.message)
    }
  }

  useEffect(() => {
    getAllCategories()
  }, [])

  useEffect(() => {
    setCategories(stateCategories)
  }, [stateCategories])

  function filterFunction(textInput) {
    // Declare variables
    var input, filter, table, tr, td, i, txtValue
    input = textInput
    filter = input.value.toUpperCase()
    table = document.getElementById("categoryTable")
    tr = table.getElementsByTagName("tr")

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[1]
      if (td) {
        txtValue = td.textContent || td.innerText
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = ""
        } else {
          tr[i].style.display = "none"
        }
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            btnShow={role !== "admin" ? false : true}
            btnText="Create Category"
            action="category"
            title="Deonicode"
            breadcrumbItem="Categories"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle className="mb-4">Categories</CardTitle>
                    </Col>
                    <Col xl={2}>
                      <CardTitle className="mb-4">
                        <input
                          type="text"
                          placeholder="search category"
                          className="form-control"
                          onChange={e => filterFunction(e.target)}
                        />
                      </CardTitle>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <table
                      className="table align-middle table-nowrap mb-0"
                      id="categoryTable"
                    >
                      <thead className="table-light">
                        <tr>
                          <th className="align-middle">ID</th>
                          <th className="align-middle">Category</th>
                          <th className="align-middle">Date</th>
                          <th className="align-middle">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan={7}>
                              <ButtonLoader
                                size={40}
                                color1={"black"}
                                color2={"black"}
                              />
                            </td>
                          </tr>
                        ) : categories?.length > 0 ? (
                          categories?.map((category, key) => (
                            <tr
                              key={"_category_" + key}
                              style={{ width: "100%" }}
                            >
                              <td>
                                <div className="font-size-16">{key + 1}</div>
                              </td>
                              <td>{category.title}</td>
                              <td>
                                {moment(category.createdAt).format(
                                  "DD-MM-YYYY HH:mm"
                                )}
                              </td>
                              <td>
                                <Button
                                  size="sm"
                                  color="success"
                                  onClick={() =>
                                    statusUpdateHandler({
                                      type: "Update",
                                      text: category.title,
                                      id: category._id,
                                    })
                                  }
                                >
                                  Edit
                                </Button>
                                <Button
                                  size="sm"
                                  className="m-1"
                                  color="danger"
                                  onClick={() =>
                                    statusUpdateHandler({
                                      type: "Delete",
                                      text: category.title,
                                      id: category._id,
                                    })
                                  }
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} className="text-center">
                              No Category Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <CreateCategory
          modal_xlarge={modal_edit}
          tog_xlarge={tog_edit}
          action={editItem}
        />
      </div>
    </React.Fragment>
  )
}

export default Category
