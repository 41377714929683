import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/lesson`
const userToken = localStorage.getItem("deonicode-admin")

const initialState = {
  lessons: [],
  lesson: null,
}
export const create = createAsyncThunk(
  "lesson/create",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${url}/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const loomLesson = createAsyncThunk(
  "lesson/loomLesson",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${url}/create-loom-lesson`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const chapterLessons = createAsyncThunk(
  "lesson/read",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`${url}/lessons/${data.id}`,{
        headers:{
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const readOne = createAsyncThunk(
  "lesson/readOne",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`${url}/lesson/${data.id}`,{
        headers:{
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const update = createAsyncThunk(
  "lesson/update",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${url}/update-lesson/${data.id}`,
        data.formData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateLoomLesson = createAsyncThunk(
  "lesson/updateLoomLesson",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${url}/update-loom-lesson/${data.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteLesson = createAsyncThunk(
  "lesson/deleteLesson",
  async (data, thunkAPI) => {
    try {
      const response = await axios.delete(`${url}/delete-lesson/${data.id}`,{
        headers:{
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteLoomLesson = createAsyncThunk(
  "lesson/deleteLoomLesson",
  async (data, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${url}/delete-loom-lesson/${data.id}`,{
          headers:{
            Authorization: `Bearer ${userToken}`,
          }
        }
      )
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const lessonSlice = createSlice({
  name: "lesson",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(create.fulfilled, (state, action) => {
        state.lesson = action.payload
      })
      .addCase(chapterLessons.fulfilled, (state, action) => {
        state.lessons = action.payload
      })
      .addCase(readOne.fulfilled, (state, action) => {
        state.lesson = action.payload
      })
  },
})

export const { logout } = lessonSlice.actions

export default lessonSlice.reducer
