import {
  postAnnouncement,
  allAnnouncement,
} from "store/reduxToolkit/reducers/announcementReducer"

export const announcements = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(allAnnouncement(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const newAnnouncement = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(postAnnouncement(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}
