import { subscribers } from "store/reduxToolkit/reducers/subscriberReducer"

export const allSubscribers = async (dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(subscribers()).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}
