import React, { useState, useEffect, useContext } from "react"
import { Card, CardBody, CardTitle, Row, Col, Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { AuthContext } from "context/authContext/authContext"
import { newRequest } from "helpers/helpers/withdrawals"
import { getSingleTeacher } from "helpers/helpers/Teachers"
import ButtonLoader from "components/Loader/ButtonLoader"

const Withdraw = () => {
  const { userId } = useContext(AuthContext)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [bonusLoading, setBonusLoading] = useState(false)

  const [amount, setAmount] = useState(0)
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [bonusRevenue, setBonusRevenue] = useState(null)

  const formatMoney = amount => {
    let dollarUSLocale = Intl.NumberFormat("en-US")
    return dollarUSLocale.format(amount)
  }

  const instructorDetails = async () => {
    const data = {
      id: userId,
    }
    const response = await getSingleTeacher(data, dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    } else {
      setTotalRevenue(response.message.payload.totalRevenue)
      setBonusRevenue(response.message.payload.referralRevenue)
    }
  }

  useEffect(() => {
    if (userId !== "undefined") {
      instructorDetails()
    }
  }, [userId])

  // request redrawal
  const requestRedrawal = async () => {
    if (userId && amount) {
      if (amount > totalRevenue) {
        toast.warning("Amount is greater than income.")
        return
      } else {
        const data = { amount, userId }
        const response = await newRequest(data, dispatch, setLoading)
        if (response.status === "error") {
          toast.error(response.res.payload)
          return
        } else {
          toast.success(response.message.payload.message)
          return
        }
      }
    } else {
      toast.error("Amount is required")
      return
    }
  }

  const requestBonusRedrawal = async () => {
    if (bonusRevenue < 20) {
      toast.warning("Minimum withdrawal is $20")
      return
    }
    const data = { amount: bonusRevenue, userId, isTutorBonus: true }
    const response = await newRequest(data, dispatch, setBonusLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
      return
    } else {
      toast.success(response.message.payload.message)
      return
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Deonicode" breadcrumbItem="Withdraw" />
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Withdraw</CardTitle>

              <div
                style={{
                  width: "30%",
                }}
              >
                <p className="h5">
                  Referral Bonus: ${formatMoney(bonusRevenue)}
                </p>
                <br />
                <Button color="primary" onClick={requestBonusRedrawal}>
                  {bonusLoading ? (
                    <ButtonLoader
                      size={30}
                      color1={"#ffffff"}
                      color2={"#ffffff"}
                    />
                  ) : (
                    "Withdraw Bonus"
                  )}
                </Button>
              </div>
              <br />
              <br />
              <div
                style={{
                  width: "30%",
                }}
              >
                <p className="h5">Total Income: ${formatMoney(totalRevenue)}</p>
                <br />
                <input
                  type="number"
                  placeholder="Withdraw Amount"
                  className="form-control"
                  onChange={e => setAmount(e.target.value)}
                />
                <br />
                <Button color="primary" onClick={requestRedrawal}>
                  {loading ? (
                    <ButtonLoader
                      size={30}
                      color1={"#ffffff"}
                      color2={"#ffffff"}
                    />
                  ) : (
                    "Send request"
                  )}
                </Button>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Withdraw
