import React, { useState } from "react"
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap"
import {
  singleLessonDelete,
  singleLoomLessonDelete,
} from "helpers/helpers/lesson"
import { singleChapterDelete } from "helpers/helpers/chapter"
import { templateDelete } from "helpers/helpers/emails"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import ButtonLoader from "components/Loader/ButtonLoader"

const Delete = ({ modal_xlarge, tog_xlarge, action }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const handleDelete = async () => {
    try {
      const data = {
        id: action.id,
      }
      if (action.type === "chapter") {
        const response = await singleChapterDelete(data, dispatch, setLoading)
        if (response.status === "error") {
          toast.error(response.res.payload)
          return
        } else {
          toast.success(response.message.payload.message)
          tog_xlarge()
          window.location.reload()
          return
        }
      }
      if (action.type === "lesson") {
        if (action.isLoom) {
          const response = await singleLoomLessonDelete(
            data,
            dispatch,
            setLoading
          )
          if (response.status === "error") {
            toast.error(response.res.payload)
            return
          } else {
            toast.success(response.message.payload.message)
            tog_xlarge()
            window.location.reload()
            return
          }
        } else {
          const response = await singleLessonDelete(data, dispatch, setLoading)
          if (response.status === "error") {
            toast.error(response.res.payload)
            return
          } else {
            toast.success(response.message.payload.message)
            tog_xlarge()
            return
          }
        }
      }
      if (action.type === "template") {
        const response = await templateDelete(data, dispatch, setLoading)
        if (response.status === "error") {
          toast.error(response.res.payload)
          return
        } else {
          toast.success(response.message.payload.message)
          tog_xlarge()
          window.location.reload()
          return
        }
      }
    } catch (error) {
      console.error(error)
      toast.error("An error occured")
    }
  }

  return (
    <div>
      <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="sl">
        <ModalHeader className="mt-0" toggle={tog_xlarge}>
          Delete
        </ModalHeader>
        <ModalBody>
          <div className="p-3">
            <p className="text-center">
              Are you sure you want to delete {action?.text}?
            </p>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {loading ? (
                <Button color="danger">
                  <ButtonLoader size={30} color1={"white"} color2={"white"} />
                </Button>
              ) : (
                <Button color="danger" onClick={handleDelete}>
                  Delete
                </Button>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default Delete
