import React, { useState, useEffect, useContext } from "react"
import { Card, CardBody, CardTitle, Row, Col, Button } from "reactstrap"
import { Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { myCourses, allCourses } from "helpers/helpers/courses"
import { AuthContext } from "context/authContext/authContext"
import ModalComponent from "components/Modal"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const Courses = () => {
  const dispatch = useDispatch()
  const { userId, role } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [modal_approve, setmodal_approve] = useState(false)
  const [approveItem, setApproveItem] = useState("")
  const [courses, setCourses] = useState([])

  const tog_approve = () => {
    setmodal_approve(!modal_approve)
  }

  const approveCourseHandler = item => {
    setApproveItem(item)
    tog_approve()
  }

  const getCourses = async () => {
    if (role === "admin") {
      const response = await allCourses(dispatch, setLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
      } else {
        setCourses(response.message.payload.courses)
        toast.success(response.message.payload.message)
      }
    } else {
      const data = {
        id: userId,
      }
      const response = await myCourses(data, dispatch, setLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
      } else {
        setCourses(response.message.payload.courses)
        toast.success(response.message.payload.message)
      }
    }
  }

  useEffect(() => {
    if (userId !== "") {
      getCourses()
    }
  }, [userId])

  function filterFunction(textInput) {
    // Declare variables
    var input, filter, table, tr, td, i, txtValue
    input = textInput
    filter = input.value.toUpperCase()
    table = document.getElementById("coursetable")
    tr = table.getElementsByTagName("tr")

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[1]
      if (td) {
        txtValue = td.textContent || td.innerText
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = ""
        } else {
          tr[i].style.display = "none"
        }
      }
    }
  }

  document.title = "Deonicode | Dashboard - Courses"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            btnShow={role === "admin" ? false : true}
            btnText="Create Course"
            action="course"
            title="Deonicode"
            breadcrumbItem="Courses"
          />
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <CardTitle className="mb-4">Courses</CardTitle>
                </Col>
                <Col xl={2}>
                  <CardTitle className="mb-4">
                    <input
                      type="text"
                      placeholder="search course"
                      className="form-control"
                      onChange={e => filterFunction(e.target)}
                    />
                  </CardTitle>
                </Col>
              </Row>
              <div className="table-responsive">
                <table
                  className="table align-middle table-nowrap mb-0"
                  id="coursetable"
                >
                  <thead className="table-light">
                    <tr>
                      <th className="align-middle">ID</th>
                      <th className="align-middle">Title</th>
                      <th className="align-middle">Category</th>
                      <th className="align-middle">Status</th>
                      <th className="align-middle">Cost</th>
                      <th className="align-middle">Sales</th>
                      <th className="align-middle">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={7}>
                          <ButtonLoader
                            size={40}
                            color1={"black"}
                            color2={"black"}
                          />
                        </td>
                      </tr>
                    ) : courses?.length > 0 ? (
                      courses &&
                      courses?.map((course, key) => (
                        <tr key={key}>
                          <td>
                            {/* <div className="form-check font-size-16">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={course._id}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={course._id}
                              >
                                &nbsp;
                              </label>
                            </div> */}
                            <div className="font-size-16">{key + 1}</div>
                          </td>
                          <td>
                            <Link to="#" className="text-body">
                              {course.title}
                            </Link>
                          </td>
                          <td>{course.category}</td>
                          <td>
                            {course.isApproved ? (
                              <span className="text-success">Approved</span>
                            ) : (
                              <span className="text-warning">Pending</span>
                            )}
                          </td>
                          <td>${course.price}</td>
                          <td>{course.saleCount}</td>

                          <td>
                            <Link to={`/course-details/${course._id}`}>
                              <Button size="sm" color="primary">
                                View
                              </Button>
                            </Link>
                            <Link to={`/edit-course/${course._id}`}>
                              {role === "admin" ? (
                                <Button size="sm" color="info m-1">
                                  More
                                </Button>
                              ) : (
                                <Button size="sm" color="success m-1">
                                  Edit
                                </Button>
                              )}
                            </Link>
                            {role === "admin" ? (
                              <>
                                {course.isApproved ? (
                                  <Button
                                    size="sm"
                                    color="danger"
                                    onClick={() =>
                                      approveCourseHandler({
                                        type: "approveCourse",
                                        text:
                                          "suspend this course (" +
                                          course.title +
                                          ")",
                                        id: course._id,
                                      })
                                    }
                                  >
                                    Suspend
                                  </Button>
                                ) : (
                                  <Button
                                    size="sm"
                                    color="success"
                                    onClick={() =>
                                      approveCourseHandler({
                                        type: "approveCourse",
                                        text:
                                          "approve this course (" +
                                          course.title +
                                          ")",
                                        id: course._id,
                                      })
                                    }
                                  >
                                    Approve
                                  </Button>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={7}>
                          <div className="text-center">No Course Found</div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </div>
        <ModalComponent
          modal_xlarge={modal_approve}
          tog_xlarge={tog_approve}
          action={approveItem}
        />
      </div>
    </React.Fragment>
  )
}

export default Courses
