import React, { useState, useEffect } from "react"
import { Col, Modal, ModalBody, ModalHeader, Row, Button } from "reactstrap"
import moment from "moment"

const ViewTransaction = ({ modal_xlarge, tog_xlarge, details }) => {
  const [course, setCourse] = useState(null)
  const [user, setUser] = useState(null)
  const [tutor, setTutor] = useState(null)
  const [transaction, setTransaction] = useState(null)

  useEffect(() => {
    setCourse(details?.course)
    setUser(details?.user)
    setTutor(details?.tutor)
    setTransaction(details?.transaction)
  }, [details])

  return (
    <div>
      <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="xl">
        <ModalHeader className="mt-0" toggle={tog_xlarge} id="no-print">
          <div>
            <Row>
              <Col>
                <Button
                  color="primary"
                  onClick={() => {
                    window.print()
                  }}
                >
                  <i className="fa fa-download" aria-hidden="true"></i>
                </Button>
              </Col>
              <Col>
                <div>Transaction</div>
              </Col>
            </Row>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="p-3">
            <div>
              <Row>
                <Col>
                  <img
                    src={course && course[0]?.coverImage?.doc}
                    alt=""
                    height={200}
                    className="rounded"
                  />
                </Col>
                <Col xl={6}>
                  <div>
                    <p className="h3">
                      <span className="ml-2">Course</span>
                    </p>
                    <p className="h4">
                      {/* <b>Course:</b> */}
                      <span className="ml-1">{course && course[0]?.title}</span>
                    </p>
                  </div>
                  <div className="mt-5">
                    <h4>Date & Time</h4>
                    <p className="h5">
                      {moment(transaction?.createdAt).format(
                        "DD-MM-YYYY HH:mm"
                      )}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="p-4">
              <Row>
                <Col>
                  <div>
                    <p className="h4">Payment Details</p>
                    <p>
                      Status:{" "}
                      <span
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        {transaction?.status}
                      </span>
                    </p>
                    <p>
                      Amount:{" "}
                      <span
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        ${transaction?.amount}
                      </span>
                    </p>
                    <p>
                      Revenue:{" "}
                      <span
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        ${transaction?.revenue}
                      </span>
                    </p>
                    <p>
                      Payment Method:
                      <span
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        {transaction?.paymentMethod}
                      </span>
                    </p>
                    <p>
                      Mobile Money Number:
                      <span
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        {transaction?.phonenumber}
                      </span>
                    </p>
                    <p>
                      Date Initiated:
                      <span
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        {moment(transaction?.dateInitiated).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                      </span>
                    </p>
                    <p>
                      Date Confirmed:
                      <span
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        {moment(transaction?.dateConfirmed).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                      </span>
                    </p>
                  </div>
                </Col>
                <Col>
                  <div>
                    <p className="h4">User Details</p>
                    <p>
                      User ID
                      <span
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        {user && user[0]?._id}
                      </span>
                    </p>
                    <p>
                      Username:{" "}
                      <span
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        {transaction?.username}
                      </span>
                    </p>
                    <p>
                      Email:
                      <span
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        {transaction?.email}
                      </span>
                    </p>
                    <p>
                      Number Of Courses:
                      <span
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        {user && user[0]?.purchasedCourses?.length}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p className="h4">Instructor Details</p>
                    <p>
                      User ID:{" "}
                      <span
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        {tutor && tutor[0]?._id}
                      </span>
                    </p>
                    <p>
                      Username:{" "}
                      <span
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        {tutor && tutor[0]?.username}
                      </span>
                    </p>
                    <p>
                      Instructor email:
                      <span
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        {tutor && tutor[0]?.email}
                      </span>
                    </p>
                    <p>
                      Phone number:
                      <span
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        {tutor && tutor[0]?.phoneNumber}
                      </span>
                    </p>
                    <p>
                      Country:
                      <span
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        {tutor && tutor[0]?.country}
                      </span>
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ViewTransaction
