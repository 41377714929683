import classNames from "classnames"
import Delete from "components/Delete"
import React, { useState } from "react"
import {
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import CreateTemplate from "./CreateTemplate"

const Template = ({ template }) => {
  const [singlebtn, setSinglebtn] = useState(false)
  // const [modal_chapter, setmodal_chapter] = useState(false)
  // const [modal_lesson, setmodal_lesson] = useState(false)
  const [modal_delete, setmodal_delete] = useState(false)
  const [action, setAction] = useState(false)
  const [updateItem, setUpdateItem] = useState(null)
  const [deleteItem, setDeleteItem] = useState(false)
  const [open, setOpen] = useState()

  const [modal_xlarge, setmodal_xlarge] = useState(false)

  const tog_xlarge = () => {
    setAction("Update")
    setUpdateItem(template)
    setmodal_xlarge(!modal_xlarge)
  }

  const tog_delete = () => {
    setmodal_delete(!modal_delete)
  }

  const handleDelete = item => {
    setDeleteItem(item)
    tog_delete()
  }

  return (
    <div className="accordion" id="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className={classNames(
              {
                collapsed: open === false,
              },
              "accordion-button fw-semibold"
            )}
            type="button"
            onClick={() => {
              setOpen(!open)
            }}
            style={{ cursor: "pointer" }}
          >
            {template.title}
          </button>
        </h2>

        <Collapse isOpen={open} className="accordion-collapse">
          <div className="accordion-body">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Dropdown
                isOpen={singlebtn}
                toggle={() => setSinglebtn(!singlebtn)}
              >
                <DropdownToggle tag="button" className="btn btn-primary">
                  Options <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={tog_xlarge}>
                    Edit Template
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      handleDelete({
                        type: "template",
                        text: template.title,
                        id: template._id,
                      })
                    }
                  >
                    Delete Template
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div
              className="mt-2"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {template.message}
            </div>
          </div>
        </Collapse>
      </div>
      <CreateTemplate
        action={action}
        modal_xlarge={modal_xlarge}
        tog_xlarge={tog_xlarge}
        updateItem={updateItem}
      />
      <Delete
        modal_xlarge={modal_delete}
        tog_xlarge={tog_delete}
        action={deleteItem}
      />
    </div>
  )
}

export default Template
