import { useContext } from "react"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Form,
  FormFeedback,
  Label,
  Input,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { connect, useDispatch, useSelector } from "react-redux"
import withRouter from "components/Common/withRouter"
import Breadcrumb from "../../components/Common/Breadcrumb"
import placeholderImage from "../../assets/images/users/placeholder.jpg"
import { editProfile, resetProfileFlag } from "../../store/actions"
import {
  updateProfileImage,
  getSingleTeacher,
  updateProfileDetails,
  updateSocials,
  updateTutorPassword,
} from "helpers/helpers/Teachers"
import {
  updateAdminPassword,
  updateAdminDetails,
} from "helpers/helpers/authentication"
import { AuthContext } from "context/authContext/authContext"
import { toast } from "react-toastify"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const UserProfile = props => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [imageLoading, setImageLoading] = useState(false)
  const [socialLoading, setSocialLoading] = useState(false)
  const [passwordLoading, setPasswordLoading] = useState(false)
  const [profileImage, setProfileImage] = useState(null)
  const [avatar, setAvatar] = useState("")
  const [email, setemail] = useState("")
  const [adminEmail, setAdminEmail] = useState("")
  const [name, setname] = useState("")
  const [age, setAge] = useState("")
  const [bio, setBio] = useState("")
  const [occupation, setOccupation] = useState("")
  const [country, setCountry] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [educationLevel, setEducationLevel] = useState("")
  const [selectedCategory, setSelectedCategory] = useState("")
  const [idx, setidx] = useState(1)
  //
  const [twitter, setTwitter] = useState("")
  const [linkedIn, setLinkedIn] = useState("")
  //
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  //
  const context = useContext(AuthContext)
  const instructor = useSelector(state => state.teachers.teacher)

  useEffect(() => {
    if (instructor !== null) {
      setAvatar(instructor?.avatar)
      setname(instructor?.username)
      setAge(instructor?.age)
      setPhoneNumber(instructor?.phoneNumber)
      setOccupation(instructor?.occupation)
      setEducationLevel(instructor?.educationLevel)
      setSelectedCategory(instructor?.targetSubject)
      setCountry(instructor?.country)
      setBio(instructor?.bio)
      // socials
      setTwitter(instructor?.twitter)
      setLinkedIn(instructor?.linkedIn)
    }
  }, [instructor])

  useEffect(() => {
    if (context) {
      setemail(context.email)
      setidx(context.userId)
      setTimeout(() => {
        props.resetProfileFlag()
      }, 3000)
    }
  }, [props.success])

  useEffect(() => {
    instructorDetails()
  }, [context.userId])

  const instructorDetails = async () => {
    if (context.userId !== "" && context.role === "instructor") {
      const data = {
        id: context.userId,
      }
      const response = await getSingleTeacher(data, dispatch, setPageLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
      }
    }
  }

  const fileValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      profileImage: profileImage || "",
    },
    validationSchema: Yup.object({
      profileImage: Yup.string().required("Please Select Your Profile Image"),
    }),
    onSubmit: async values => {
      let formData = new FormData()
      formData.append("profileImage", values.profileImage)
      const data = {
        id: context.userId,
        formData,
      }
      const response = await updateProfileImage(data, dispatch, setImageLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
        setImageLoading(false)
        return
      } else {
        toast.success(response.message.payload.message)
        setImageLoading(false)
        return
      }
    },
  })

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      idx: idx || "",
      email: email || "",
      username: name || "",
      age: age || "",
      bio: bio || "",
      occupation: occupation || "",
      phoneNumber: phoneNumber || "",
      educationLevel: educationLevel || "",
      targetSubject: selectedCategory || "",
      country: country || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your User Name"),
      email: Yup.string().required("Please Enter Your User Email"),
      age: Yup.string().required("Please Enter Your Age"),
      bio: Yup.string().required("Please Enter Your Bio"),
      occupation: Yup.string().required("Please Enter Your Occupation"),
      phoneNumber: Yup.string().required("Please Enter Your Phone Number"),
      bio: Yup.string().required("Please Enter Your Bio"),
      educationLevel: Yup.string().required(
        "Please Enter Your Level of Education"
      ),
      targetSubject: Yup.string().required(
        "Please Enter Your Selected Category"
      ),
    }),
    onSubmit: async values => {
      const data = {
        id: context.userId,
        values,
      }
      const response = await updateProfileDetails(data, dispatch, setLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
        setLoading(false)
        return
      } else {
        toast.success(response.message.payload.message)
        setLoading(false)
        return
      }
    },
  })

  const socialValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      twitter: twitter || "",
      linkedIn: linkedIn || "",
    },
    onSubmit: async values => {
      const data = {
        id: context.userId,
        values,
      }
      const response = await updateSocials(data, dispatch, setSocialLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
        setImageLoading(false)
        return
      } else {
        toast.success(response.message.payload.message)
        setImageLoading(false)
        return
      }
    },
  })

  const passwordValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      currentPassword: currentPassword || "",
      newPassword: newPassword || "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required(
        "Please Enter Your Current Password"
      ),
      newPassword: Yup.string().required("Please Enter Your New Password"),
    }),
    onSubmit: async values => {
      const data = {
        id: context.userId,
        values,
      }
      if (context.role === "admin") {
        const response = await updateAdminPassword(
          data,
          dispatch,
          setPasswordLoading
        )
        if (response.status === "error") {
          toast.error(response.res.payload)
          setPasswordLoading(false)
          return
        } else {
          toast.success(response.message.payload.message)
          setPasswordLoading(false)
          return
        }
      } else {
        const response = await updateTutorPassword(
          data,
          dispatch,
          setPasswordLoading
        )
        if (response.status === "error") {
          toast.error(response.res.payload)
          setPasswordLoading(false)
          return
        } else {
          toast.success(response.message.payload.message)
          setPasswordLoading(false)
          return
        }
      }
    },
  })

  const emailValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: email || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: async values => {
      const data = {
        id: context.userId,
        values,
      }
      const response = await updateAdminDetails(data, dispatch, setLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
        setLoading(false)
        return
      } else {
        toast.success(response.message.payload.message)
        setLoading(false)
        return
      }
    },
  })

  document.title = "Profile | Deonicode - React Admin & Dashboard Template"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Deonicode" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {props.error && props.error ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}
              {props.success ? (
                <Alert color="success">{props.success}</Alert>
              ) : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-3">
                      <img
                        src={(avatar && avatar.doc) || placeholderImage}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="align-self-center flex-1">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">Id no: #{idx}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Update User Details</h4>
          {pageLoading ? (
            <ButtonLoader size={40} color1={"black"} color2={"black"} />
          ) : (
            <>
              {context.role === "instructor" ? (
                <>
                  <Card>
                    <CardBody>
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          fileValidation.handleSubmit()
                          return false
                        }}
                      >
                        <div className="form-group">
                          <Label className="form-label">Profile Image</Label>
                          <Input
                            name="profileImage"
                            className="form-control"
                            placeholder="Select Profile Image"
                            type="file"
                            accept="image/*"
                            onChange={e => setProfileImage(e.target.files[0])}
                            onBlur={fileValidation.handleBlur}
                            value={fileValidation.values.profileImage.filename}
                            invalid={
                              fileValidation.touched.profileImage &&
                              fileValidation.errors.profileImage
                                ? true
                                : false
                            }
                          />
                          {fileValidation.touched.profileImage &&
                          fileValidation.errors.profileImage ? (
                            <FormFeedback type="invalid">
                              {fileValidation.errors.profileImage}
                            </FormFeedback>
                          ) : null}
                          <Input name="idx" value={idx} type="hidden" />
                        </div>
                        <div className="text-center mt-4">
                          <Button type="submit" color="primary">
                            {imageLoading
                              ? "Loading..."
                              : "Update Profile Image"}
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        <div className="form-group">
                          <Label className="form-label">User Details</Label>
                          <Input
                            name="username"
                            className="form-control"
                            placeholder="Enter Username"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.username || ""}
                            invalid={
                              validation.touched.username &&
                              validation.errors.username
                                ? true
                                : false
                            }
                          />
                          <Input
                            name="email"
                            className="form-control mt-2"
                            placeholder="Enter User Email"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          <Input
                            name="phoneNumber"
                            className="form-control mt-2"
                            placeholder="Enter User Phone Number"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phoneNumber || ""}
                            invalid={
                              validation.touched.phoneNumber &&
                              validation.errors.phoneNumber
                                ? true
                                : false
                            }
                          />
                          <Input
                            name="age"
                            className="form-control mt-2"
                            placeholder="Enter User Age"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.age || ""}
                            invalid={
                              validation.touched.age && validation.errors.age
                                ? true
                                : false
                            }
                          />
                          <Input
                            name="occupation"
                            className="form-control mt-2"
                            placeholder="Enter User Occupation"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.occupation || ""}
                            invalid={
                              validation.touched.occupation &&
                              validation.errors.occupation
                                ? true
                                : false
                            }
                          />
                          <Input
                            name="educationLevel"
                            className="form-control mt-2"
                            placeholder="Enter User Level of Education"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.educationLevel || ""}
                            invalid={
                              validation.touched.educationLevel &&
                              validation.errors.educationLevel
                                ? true
                                : false
                            }
                          />
                          <Input
                            name="targetSubject"
                            className="form-control mt-2"
                            placeholder="Enter User Category"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.targetSubject || ""}
                            invalid={
                              validation.touched.targetSubject &&
                              validation.errors.targetSubject
                                ? true
                                : false
                            }
                          />
                          <Input
                            name="country"
                            className="form-control mt-2"
                            placeholder="Enter User Country"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.country || ""}
                            invalid={
                              validation.touched.country &&
                              validation.errors.country
                                ? true
                                : false
                            }
                          />
                          <Input
                            name="bio"
                            className="form-control mt-2"
                            placeholder="Enter User Bio"
                            type="textarea"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.bio || ""}
                            invalid={
                              validation.touched.bio && validation.errors.bio
                                ? true
                                : false
                            }
                          />
                          {validation.touched.username &&
                          validation.errors.username ? (
                            <FormFeedback type="invalid">
                              {validation.errors.username}
                            </FormFeedback>
                          ) : null}
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                          {validation.touched.phoneNumber &&
                          validation.errors.phoneNumber ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phoneNumber}
                            </FormFeedback>
                          ) : null}
                          {validation.touched.age && validation.errors.age ? (
                            <FormFeedback type="invalid">
                              {validation.errors.age}
                            </FormFeedback>
                          ) : null}
                          {validation.touched.occupation &&
                          validation.errors.occupation ? (
                            <FormFeedback type="invalid">
                              {validation.errors.occupation}
                            </FormFeedback>
                          ) : null}
                          {validation.touched.educationLevel &&
                          validation.errors.educationLevel ? (
                            <FormFeedback type="invalid">
                              {validation.errors.educationLevel}
                            </FormFeedback>
                          ) : null}
                          {validation.touched.selectedCategory &&
                          validation.errors.selectedCategory ? (
                            <FormFeedback type="invalid">
                              {validation.errors.selectedCategory}
                            </FormFeedback>
                          ) : null}
                          {validation.touched.bio && validation.errors.bio ? (
                            <FormFeedback type="invalid">
                              {validation.errors.bio}
                            </FormFeedback>
                          ) : null}
                          {validation.touched.country &&
                          validation.errors.country ? (
                            <FormFeedback type="invalid">
                              {validation.errors.country}
                            </FormFeedback>
                          ) : null}
                          <Input name="idx" value={idx} type="hidden" />
                        </div>
                        <div className="text-center mt-4">
                          <Button type="submit" color="primary">
                            {loading ? "Loading..." : " Update User Details"}
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          socialValidation.handleSubmit()
                          return false
                        }}
                      >
                        <div className="form-group">
                          <Label className="form-label">User Socials</Label>
                          <Input
                            name="twitter"
                            className="form-control mt-2"
                            placeholder="Enter Twitter Link"
                            type="text"
                            onChange={socialValidation.handleChange}
                            onBlur={socialValidation.handleBlur}
                            value={socialValidation.values.twitter || ""}
                          />
                          <Input
                            name="linkedIn"
                            className="form-control mt-2"
                            placeholder="Enter LinkedIn Link"
                            type="text"
                            onChange={socialValidation.handleChange}
                            onBlur={socialValidation.handleBlur}
                            value={socialValidation.values.linkedIn || ""}
                          />

                          <Input name="idx" value={idx} type="hidden" />
                        </div>
                        <div className="text-center mt-4">
                          <Button type="submit" color="primary">
                            {socialLoading
                              ? "Loading..."
                              : " Update Social Details"}
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </>
              ) : (
                <Card>
                  <CardBody>
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        emailValidation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="form-group">
                        <Label className="form-label">User Email</Label>
                        <Input
                          name="email"
                          className="form-control mt-2"
                          placeholder="Enter User Email"
                          type="text"
                          onChange={emailValidation.handleChange}
                          onBlur={emailValidation.handleBlur}
                          value={emailValidation.values.email || ""}
                          invalid={
                            emailValidation.touched.email &&
                            emailValidation.errors.email
                              ? true
                              : false
                          }
                        />
                        {emailValidation.touched.email &&
                        emailValidation.errors.email ? (
                          <FormFeedback type="invalid">
                            {emailValidation.errors.email}
                          </FormFeedback>
                        ) : null}

                        <Input name="idx" value={idx} type="hidden" />
                      </div>
                      <div className="text-center mt-4">
                        <Button type="submit" color="primary">
                          {loading ? "Loading..." : " Update Email"}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              )}
              <Card>
                <CardBody>
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      passwordValidation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="form-group">
                      <Label className="form-label">User Password</Label>
                      <Input
                        name="currentPassword"
                        className="form-control"
                        placeholder="Enter Current Password"
                        type="text"
                        onChange={passwordValidation.handleChange}
                        onBlur={passwordValidation.handleBlur}
                        value={passwordValidation.values.currentPassword || ""}
                        invalid={
                          passwordValidation.touched.currentPassword &&
                          passwordValidation.errors.currentPassword
                            ? true
                            : false
                        }
                      />
                      <Input
                        name="newPassword"
                        className="form-control mt-2"
                        placeholder="Enter New Password"
                        type="text"
                        onChange={passwordValidation.handleChange}
                        onBlur={passwordValidation.handleBlur}
                        value={passwordValidation.values.newPassword || ""}
                        invalid={
                          passwordValidation.touched.newPassword &&
                          passwordValidation.errors.newPassword
                            ? true
                            : false
                        }
                      />
                      {passwordValidation.touched.currentPassword &&
                      passwordValidation.errors.currentPassword ? (
                        <FormFeedback type="invalid">
                          {passwordValidation.errors.currentPassword}
                        </FormFeedback>
                      ) : null}
                      {passwordValidation.touched.newPassword &&
                      passwordValidation.errors.newPassword ? (
                        <FormFeedback type="invalid">
                          {passwordValidation.errors.newPassword}
                        </FormFeedback>
                      ) : null}
                      <Input name="idx" value={idx} type="hidden" />
                    </div>
                    <div className="text-center mt-4">
                      <Button type="submit" color="primary">
                        {passwordLoading ? "Loading..." : " Update Password"}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, { editProfile, resetProfileFlag })(UserProfile)
)
