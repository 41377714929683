import React, { useState } from "react"
import { Col, Modal, ModalBody, ModalHeader, Row, Button } from "reactstrap"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { createChapter, updateChapter } from "helpers/helpers/chapter"
import { useDispatch } from "react-redux"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const CreateChapter = ({ modal_xlarge, tog_xlarge, action }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { id } = useParams()
  const [chapter, setChapter] = useState("")

  const handleCreateChapter = async () => {
    if (chapter) {
      if (action.type === "Create") {
        const data = {
          courseId: id,
          title: chapter,
        }
        const response = await createChapter(data, dispatch, setLoading)
        if (response.status === "error") {
          toast.error(response.res.payload)
          return
        } else {
          toast.success(response.message.payload.message)
          tog_xlarge()
          window.location.reload()
          return
        }
      }
      if (action.type === "Update") {
        const data = {
          id: action.id,
          title: chapter,
        }
        const response = await updateChapter(data, dispatch, setLoading)
        if (response.status === "error") {
          toast.error(response.res.payload)
          return
        } else {
          toast.success(response.message.payload.message)
          tog_xlarge()
          window.location.reload()
          return
        }
      }
    } else {
      toast.error("chapter is required")
    }
  }

  return (
    <div>
      <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="sl">
        <ModalHeader className="mt-0" toggle={tog_xlarge}>
          {action.type} Chapter
        </ModalHeader>
        <ModalBody>
          <div className="p-3">
            <input
              type="text"
              placeholder="Title"
              className="form-control"
              onChange={e => setChapter(e.target.value)}
            />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {loading ? (
                <Button color="primary">
                  <ButtonLoader size={30} color1={"white"} color2={"white"} />
                </Button>
              ) : (
                <Button color="primary" onClick={handleCreateChapter}>
                  {action.type}
                </Button>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default CreateChapter
