import React, { useState, useEffect, useContext } from "react"
import { Button, Card, CardBody } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AdminLesson from "components/Course/AdminLesson"
import EditCourseComponent from "components/Course/EditCourse"
// import { toast } from "react-toastify"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { AuthContext } from "context/authContext/authContext"
import { singleCourse } from "helpers/helpers/courses"
import { myChapters } from "helpers/helpers/chapter"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const EditCourse = () => {
  const dispatch = useDispatch()
  const { role } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [course, setCourse] = useState(null)
  const [chapters, setChapters] = useState([])

  const { id } = useParams()

  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [action, setAction] = useState("")

  const tog_xlarge = () => {
    setAction("Update Course")
    setmodal_xlarge(!modal_xlarge)
  }

  const myCourseChapters = async () => {
    const data = {
      id,
    }
    const response = await myChapters(data, dispatch, setLoading)
    if (response.status === "error") {
      return
    } else {
      setChapters(response.message.payload.chapters)
    }
  }

  const courseDetails = async () => {
    const data = {
      id,
    }
    const response = await singleCourse(data, dispatch, setLoading)
    if (response.status === "error") {
      return
    } else {
      setCourse(response.message.payload.course)
    }
  }

  useEffect(() => {
    courseDetails()
    myCourseChapters()
  }, [id])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            btnShow={true}
            title="Deonicode"
            breadcrumbItem="EditCourse"
            btnText="Create Chapter"
            action="chapter"
          />
          <Card>
            <CardBody>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className="h6 card-title-desc">Course Title</p>
                <Button onClick={tog_xlarge}>Edit Course</Button>
              </div>

              {loading ? (
                <ButtonLoader size={40} color1={"black"} color2={"black"} />
              ) : chapters.length > 0 ? (
                chapters?.map((chapter, index) => (
                  <AdminLesson chapter={chapter} key={index} />
                ))
              ) : (
                "No Chapters Found"
              )}
            </CardBody>
          </Card>
        </div>
      </div>
      <EditCourseComponent
        action={action}
        modal_xlarge={modal_xlarge}
        tog_xlarge={tog_xlarge}
        courseEdit={course}
      />
    </React.Fragment>
  )
}

export default EditCourse
