import React, { useState, useContext, useEffect } from "react"
import { Col, Modal, ModalBody, ModalHeader, Row, Button } from "reactstrap"
import { Tooltip } from "react-tooltip"
import Select from "react-select"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { createCourse } from "helpers/helpers/courses"
import { allCategories } from "helpers/helpers/categories"
import { getPriceRange } from "helpers/helpers/settings"
import { AuthContext } from "context/authContext/authContext"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"
import { Editor } from "primereact/editor"

const CreateCourse = ({ modal_xlarge, tog_xlarge, action }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [priceRangeLoading, setPriceRangeLoading] = useState(false)
  const { userId } = useContext(AuthContext)
  const [categories, setCategories] = useState([])
  const [courseTitle, setCourseTitle] = useState("")
  const [courseCategory, setCourseCategory] = useState("")
  const [introVideoUrl, setIntroVideoUrl] = useState("")
  const [courseImage, setCourseImage] = useState(null)
  const [coursePrice, setCoursePrice] = useState(0)
  const [courseLevel, setCourseLevel] = useState("")
  const [courseDuration, setCourseDuration] = useState("")
  const [courseLanguage, setCourseLanguage] = useState("")
  const [courseTag, setCourseTag] = useState("")
  const [courseTags, setCourseTags] = useState([])
  const [courseDesc, setCourseDesc] = useState("")
  const [priceRange, setPriceRange] = useState(null)
  const [priceRangeError, setPriceRangeError] = useState(false)

  const optionGroup = [
    {
      label: "Categories",
      options: categories.map(item => ({
        label: item.title,
        value: item.title,
      })),
    },
  ]

  const courseLevelGroup = [
    {
      label: "Course Level",
      options: [
        { label: "beginner", value: "Beginner" },
        { label: "intermediate", value: "Intermediate" },
        { label: "professional", value: "Professional" },
        { label: "expert", value: "Expert" },
      ],
    },
  ]

  const courseLanguageGroup = [
    {
      label: "Course Lnaguage",
      options: [
        { label: "English", value: "English" },
        { label: "French", value: "French" },
      ],
    },
  ]

  const getAllCategories = async () => {
    const response = await allCategories(dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    } else {
      setCategories(response.message.payload.categories)
      toast.success(response.message.payload.message)
    }
  }

  const getPriceRangeHandler = async () => {
    try {
      const response = await getPriceRange(dispatch, setPriceRangeLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
        return
      }
      setPriceRange(response.message.payload)
      return
    } catch (error) {
      toast.error("An error occured")
    }
  }

  const checkPriceRange = () => {
    if (
      coursePrice < parseInt(priceRange?.minPrice) ||
      coursePrice > parseInt(priceRange?.maxPrice)
    ) {
      setPriceRangeError(true)
    } else {
      setPriceRangeError(false)
    }
  }

  useEffect(() => {
    checkPriceRange()
  }, [coursePrice])

  useEffect(() => {
    getAllCategories()
    getPriceRangeHandler()
  }, [])

  const setCourseTagHangler = () => {
    if (courseTag) {
      setCourseTags([...courseTags, courseTag])
      setCourseTag("")
      document.getElementById("tag-text-field").value = ""
    } else {
      toast.error("Tag is required")
    }
  }

  const removeTagHandler = item => {
    const newTagArray = courseTags.filter(tag => tag !== item)
    setCourseTags(newTagArray)
  }

  const handleCourseCreation = async () => {
    if (
      courseTitle &&
      courseCategory &&
      courseImage &&
      coursePrice &&
      courseLevel &&
      courseDuration &&
      courseLanguage &&
      courseDesc &&
      courseTags.length > 0
    ) {
      if (
        coursePrice < parseInt(priceRange?.minPrice) ||
        coursePrice > parseInt(priceRange?.maxPrice)
      ) {
        toast.error("price is out of range")
        return
      }
      let data = new FormData()
      data.append("instructorId", userId)
      data.append("title", courseTitle)
      data.append("desc", JSON.stringify(courseDesc))
      data.append("category", courseCategory)
      data.append("coverImage", courseImage)
      data.append("price", coursePrice)
      data.append("courseLevel", courseLevel)
      data.append("duration", courseDuration)
      data.append("language", courseLanguage)
      data.append("introVideoUrl", introVideoUrl)
      for (let i = 0; i < courseTags.length; i++) {
        data.append("tags", courseTags[i])
      }

      const response = await createCourse(data, dispatch, setLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
      } else {
        toast.success(response.message.payload.message)
        tog_xlarge()
        window.location.reload()
      }
    } else {
      toast.error("All fields are required")
    }
  }

  return (
    <div>
      <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="xl">
        <ModalHeader className="mt-0" toggle={tog_xlarge}>
          {action} Course
        </ModalHeader>
        <ModalBody>
          <div className="p-3">
            <input
              type="text"
              placeholder="Course Title *"
              className="form-control"
              onChange={e => setCourseTitle(e.target.value)}
            />
            <br />
            <p className="h6">Category: *</p>
            <Select
              onChange={e => setCourseCategory(e.value)}
              options={optionGroup}
              classNamePrefix="select2-selection"
            />
            <br />
            <p className="h6">
              Please provide a link to an introductory video. You can always
              provide one later, but your course will not be approved until you
              do.
            </p>
            <input
              type="text"
              placeholder="Introductory video url"
              className="form-control"
              onChange={e => setIntroVideoUrl(e.target.value)}
            />
            <br />
            <p className="h6">Cover image: *</p>
            <input
              type="file"
              accept="image/*"
              placeholder="Cover image *"
              className="form-control"
              onChange={e => setCourseImage(e.target.files[0])}
            />
            <br />
            <p className="h6">
              Price Range: ${priceRange?.minPrice} - ${priceRange?.maxPrice}
            </p>
            {priceRangeError && (
              <p className="h6 text-danger">Your Price is Out Of Range</p>
            )}
            <input
              type="number"
              placeholder="Price USD*"
              className="form-control"
              onChange={e => setCoursePrice(e.target.value)}
            />
            <br />
            <p className="h6">Course Level: *</p>
            <Select
              onChange={e => setCourseLevel(e.value)}
              options={courseLevelGroup}
              classNamePrefix="select2-selection"
            />
            <br />
            <input
              type="number"
              placeholder="Course duration in hours *"
              className="form-control"
              onChange={e => setCourseDuration(e.target.value)}
            />
            <br />
            <p className="h6">Course Language: *</p>
            <Select
              onChange={e => setCourseLanguage(e.value)}
              options={courseLanguageGroup}
              classNamePrefix="select2-selection"
            />
            <br />
            <div>
              <ul>
                {courseTags.length > 0
                  ? courseTags.map((item, index) => (
                      <li key={index}>
                        {item}{" "}
                        <a
                          href="#"
                          className="m-2 text-danger"
                          onClick={() => removeTagHandler(item)}
                        >
                          remove
                        </a>
                      </li>
                    ))
                  : ""}
              </ul>
              <Row>
                <Col>
                  <input
                    type="text"
                    id="tag-text-field"
                    placeholder="Add tags"
                    className="form-control"
                    onChange={e => setCourseTag(e.target.value)}
                  />
                </Col>

                <a
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={`
                    Add keywords or terms associated with this course
                    to help users easily find your course. e.g Frontend, Blockchain, Tailwind CSS ...
                  `}
                  style={{
                    width: "20px",
                  }}
                  className="p-0 mt-2 rounded"
                >
                  <i className="fas fa-question-circle m-0 p-1 "></i>
                </a>
                <Tooltip id="my-tooltip" />
                <Col>
                  <Button color="primary" onClick={setCourseTagHangler}>
                    Add
                  </Button>
                </Col>
              </Row>
            </div>
            <br />
            <p className="h6">Course description and expected outcomes *</p>
      
             <Editor onTextChange={(e) => setCourseDesc(e.htmlValue)} style={{ height: '320px' }} />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {loading ? (
                <Button color="primary">
                  <ButtonLoader size={30} color1={"white"} color2={"white"} />
                </Button>
              ) : (
                <Button color="primary" onClick={handleCourseCreation}>
                  {action}
                </Button>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default CreateCourse
