import React, { useState, useEffect } from "react"
import { Card, Input } from "reactstrap"

//Import Email Sidebar
// import { Editor } from "react-draft-wysiwyg"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { getTemplates, sendEmails } from "helpers/helpers/emails"
import { toast } from "react-toastify"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const EmailCompose = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [messageBody, setMessageBody] = useState("")
  const [audience, setAudience] = useState("")
  const [subject, setSubject] = useState("")
  const templates = useSelector(state => state.mails.templates)

  const optionGroup = [
    {
      label: "Templates",
      options:
        templates.length > 0
          ? templates.map(item => ({ label: item.title, value: item.message }))
          : { label: "Loading...", value: "Loading..." },
    },
  ]

  const targetAudience = [
    {
      label: "Target Audience",
      options: [
        { label: "Students", value: "users" },
        { label: "Tutors", value: "instructors" },
        { label: "Subscribers", value: "subscribers" },
        { label: "Everyone", value: "everyone" },
      ],
    },
  ]

  const getAllTemplates = async () => {
    try {
      const response = await getTemplates(dispatch, setLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getAllTemplates()
  }, [])

  const sendMailsHandler = async () => {
    try {
      if (audience && subject && messageBody) {
        const data = {
          targetAudience: audience,
          subject,
          message: messageBody,
        }
        const response = await sendEmails(data, dispatch, setBtnLoading)
        if (response.status === "error") {
          toast.error(response.res.payload)
        } else {
          toast.success(response.message.payload.message)
          window.location.reload()
        }
      } else {
        toast.error("Please check all fields")
      }
    } catch (error) {
      toast.error("An error occured")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Deonicode" breadcrumbItem="Send Email" />

        {/* Render Email SideBar */}

        <div className="mb-3 p-12">
          <Card>
            <div className="card-body">
              <div>
                <div className="mb-3">
                  <p>Select Template</p>
                  <Select
                    onChange={e => {
                      setMessageBody(e.value)
                    }}
                    options={optionGroup}
                    classNamePrefix="select2-selection"
                  />
                </div>
                <div className="mb-3">
                  <p>Target Audience</p>
                  <Select
                    onChange={e => {
                      setAudience(e.value)
                    }}
                    options={targetAudience}
                    classNamePrefix="select2-selection"
                  />
                </div>
                <div className="mb-3">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                    onChange={e => setSubject(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <form method="post">
                    {/* <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                    /> */}
                    <textarea
                      type="text"
                      placeholder="Body"
                      className="form-control"
                      rows="10"
                      value={messageBody || ""}
                      onChange={e => setMessageBody(e.target.value)}
                    />
                  </form>
                </div>

                <div className="btn-toolbar form-group mb-0">
                  <div className="">
                    {/* <button
                      type="button"
                      className="btn btn-success waves-effect waves-light me-1"
                    >
                      <i className="fa fa-save"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-success waves-effect waves-light me-1"
                    >
                      <i className="fa fa-trash-alt"></i>
                    </button> */}
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      onClick={sendMailsHandler}
                    >
                      <span>
                        {btnLoading ? (
                          <ButtonLoader
                            size={30}
                            color1={"white"}
                            color2={"white"}
                          />
                        ) : (
                          <>
                            Send
                            <i className="fab fa-telegram-plane ms-2"></i>
                          </>
                        )}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EmailCompose
