import React, { useState } from "react"
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap"
import Select from "react-select"
import { updateRequest } from "helpers/helpers/withdrawals"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const WithdrawalModal = ({ modal_xlarge, tog_xlarge, action }) => {
  const dispatch = useDispatch()
  const [status, setStatus] = useState("")
  const [loading, setLoading] = useState(false)

  const selectOptions = [
    {
      label: "User Status",
      options: [
        { label: "Approve", value: "approved" },
        { label: "Reject", value: "rejected" },
        { label: "Pending", value: "pending" },
      ],
    },
  ]

  const withdrawalHandler = async () => {
    if (status) {
      const data = {
        id: action.id,
        status,
      }
      const response = await updateRequest(data, dispatch, setLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
        setStatus("")
        return
      } else {
        toast.success(response.message.payload.message)
        setStatus("")
        tog_xlarge()
        window.location.reload()
        return
      }
    } else {
      toast.error("Please select status action")
      return
    }
  }
  return (
    <div>
      <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="sl">
        <ModalHeader className="mt-0" toggle={tog_xlarge}>
          {action.type === "approveCourse" ? "Approve course" : ""}
        </ModalHeader>
        <ModalBody>
          <div className="p-3">
            <p className="text-center">{action.text}?</p>
            <br />
            <div>
              <Select
                onChange={e => setStatus(e.value)}
                options={selectOptions}
                classNamePrefix="select2-selection"
              />
            </div>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {loading ? (
                <Button color="success">
                  <ButtonLoader size={30} color1={"white"} color2={"white"} />
                </Button>
              ) : (
                <Button color="success" onClick={withdrawalHandler}>
                  {"Proceed"}
                </Button>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default WithdrawalModal
