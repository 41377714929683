import React, { useState, useEffect, useContext } from "react"
import "./Announcement.css"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import { Editor } from "react-draft-wysiwyg"
import { Button, Card, CardBody } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { AuthContext } from "context/authContext/authContext"
import { getSingleTeacher } from "helpers/helpers/Teachers"
import { announcements, newAnnouncement } from "helpers/helpers/announcements"
import { myCourses } from "helpers/helpers/courses"
import { format } from "timeago.js"
import draftToHtml from "draftjs-to-html"
import { toast } from "react-toastify"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const Announcement = () => {
  const dispatch = useDispatch()
  const { userId, role } = useContext(AuthContext)
  const [btnLoading, setBtnLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [courses, setCourses] = useState([])
  const [announcement, setAnnouncement] = useState("")
  const [selectedCourseId, setSelectedCourseId] = useState("")
  const allAnoouncements = useSelector(
    state => state.announcement.announcements
  )
  const instructor = useSelector(state => state.teachers.teacher)

  const courseListing = [
    {
      label: "Course Level",
      options:
        courses &&
        courses.map((item, index) => ({ label: item.title, value: item._id })),
    },
  ]

  const getCourses = async () => {
    const data = {
      id: userId,
    }
    const response = await myCourses(data, dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    } else {
      setCourses(response.message.payload.courses)
    }
  }

  const instructorDetails = async () => {
    if (userId !== "" && role === "instructor") {
      const data = {
        id: userId,
      }
      await getSingleTeacher(data, dispatch, setLoading)
    }
  }

  const getAnnouncements = async () => {
    const data = {
      courseId: selectedCourseId,
    }
    await announcements(data, dispatch, setLoading)
  }

  const postAnnouncement = async () => {
    if (announcement !== "" && selectedCourseId !== "") {
      const data = {
        courseId: selectedCourseId,
        content: JSON.stringify(announcement),
      }
      const response = await newAnnouncement(data, dispatch, setBtnLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
      } else {
        toast.success(response.message.payload.message)
      }
    } else {
      toast.error("Message blank")
      return
    }
  }

  useEffect(() => {
    if (selectedCourseId && selectedCourseId !== "") {
      getAnnouncements()
    }
  }, [selectedCourseId])

  useEffect(() => {
    if (userId !== "") {
      getCourses()
    }
  }, [userId])

  useEffect(() => {
    instructorDetails()
  }, [userId])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid"></div>
        <Breadcrumbs title="Deonicode" breadcrumbItem="Mentorhip" />
        <Select
          onChange={e => setSelectedCourseId(e.value)}
          options={courseListing}
          classNamePrefix="select2-selection"
        />
        <br />
        <Card>
          <CardBody>
            <div className="chat-txt">
              {loading ? (
                <div>
                  <ButtonLoader size={40} color1={"black"} color2={"black"} />
                </div>
              ) : allAnoouncements && allAnoouncements.length > 0 ? (
                allAnoouncements.map((item, index) => (
                  <div className="chat-msg-1" key={index}>
                    <img
                      src={"/favicon.png"}
                      height={35}
                      width={35}
                      className="chat-img rounded-circle"
                    />
                    <div className="chat-msg">
                      <div className="d-flex">
                        <p className="h6">{instructor?.username}</p>
                        {/* <div className="chat-actions">
                          <div>
                            <i className="fa fa-trash"></i>
                          </div>
                          <div>
                            <i className="fa fa-edit"></i>
                          </div>
                        </div> */}
                      </div>
                      <div
                        // className="m-0 content"
                        dangerouslySetInnerHTML={{
                          __html: draftToHtml(JSON.parse(item?.content)),
                        }}
                      />
                      <div className="mt-2">{format(item?.createdAt)}</div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center">
                  <p>No Announcement Found</p>
                </div>
              )}
            </div>
            <Editor
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onChange={e => setAnnouncement(e)}
            />
            <div className="send-btn mt-4">
              {btnLoading ? (
                <Button color="primary">
                  <ButtonLoader size={30} color1={"white"} color2={"white"} />
                </Button>
              ) : (
                <Button color="primary" onClick={postAnnouncement}>
                  {"Send"}
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default Announcement
