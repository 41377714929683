import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import { toast } from "react-toastify"
import { useParams } from "react-router-dom"
import moment from "moment"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch } from "react-redux"
import { getSingleTeacher } from "helpers/helpers/Teachers"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const TeacherDetails = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [teacher, setTeacher] = useState({})
  const { id } = useParams()

  const teacherDetails = async () => {
    const data = {
      id,
    }
    const response = await getSingleTeacher(data, dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    } else {
      setTeacher(response.message.payload)
    }
  }

  useEffect(() => {
    teacherDetails()
  }, [id])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            // maintitle="Deonicode"
            title="Deonicode"
            breadcrumbItem="Tutor Details"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {loading ? (
                    <ButtonLoader size={40} color1={"black"} color2={"black"} />
                  ) : (
                    <>
                      <Row>
                        <Col xl={3} lg={3} sm={12}>
                          <img
                            className="rounded"
                            src={teacher?.avatar?.doc}
                            alt={teacher?.username}
                            width="100%"
                            height="300"
                          />
                        </Col>
                        <Col xl={9} lg={9} sm={12}>
                          <div>
                            <span className="fw-bold">Full Name: </span>
                            {teacher?.username}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Age: </span>{" "}
                            {teacher?.age}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Email: </span>{" "}
                            {teacher?.email}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Phone number: </span>{" "}
                            {teacher?.phoneNumber}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Country: </span>
                            {teacher?.country
                              ? teacher?.country
                              : "No Country Found"}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Education: </span>{" "}
                            {teacher?.educationLevel}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Occupation: </span>{" "}
                            {teacher?.occupation}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Created account: </span>
                            {moment(teacher?.createdAt).format(
                              "DD-MM-YYYY HH:mm"
                            )}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Account status: </span>
                            {teacher?.status === "pending" ? (
                              <span className="text-warning bold">Pending</span>
                            ) : teacher?.status === "active" ? (
                              <span className="text-success bold">Active</span>
                            ) : teacher?.status === "suspended" ? (
                              <span className="text-dark bold">Suspended</span>
                            ) : (
                              <span className="text-danger bold">
                                Deactivated
                              </span>
                            )}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Resume: </span>
                            <a href={teacher?.resume?.doc} target="_blank">
                              Resume
                            </a>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="mt-4">
                            <span className="fw-bold">Bio information: </span>
                            <div className="mt-2">
                              {teacher?.bio
                                ? teacher?.bio
                                : "No Bio Data Found"}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TeacherDetails
