import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/mails`
const userToken = localStorage.getItem("deonicode-admin")

const initialState = {
  templates: [],
}

export const sendMails = createAsyncThunk(
  "mails/sendMails",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${url}/send-mails`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const createTemplate = createAsyncThunk(
  "mails/createTemplate",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${url}/create-template`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const templates = createAsyncThunk("mails/templates", async thunkAPI => {
  try {
    const response = await axios.get(`${url}/templates`,{
      headers:{
        Authorization: `Bearer ${userToken}`,
      }
    })
    return response.data
  } catch (error) {
    const message =
      (error.message && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const updateTemplate = createAsyncThunk(
  "mails/updateTemplate",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${url}/update-template/${data.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteTemplate = createAsyncThunk(
  "mails/deleteTemplate",
  async (data, thunkAPI) => {
    try {
      const response = await axios.delete(`${url}/delete-template/${data.id}`,{
        headers:{
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const authSlice = createSlice({
  name: "mails",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(templates.fulfilled, (state, action) => {
      state.templates = action.payload
    })
  },
})

export const { logout } = authSlice.actions

export default authSlice.reducer
