import React, { useState, useEffect } from "react"
import { Col, Modal, ModalBody, ModalHeader, Row, Button } from "reactstrap"
import { toast } from "react-toastify"
import {
  createCategory,
  updateCategory,
  categoryDelete,
} from "helpers/helpers/categories"
import { useDispatch } from "react-redux"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const CreateCategory = ({ modal_xlarge, tog_xlarge, action }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState("")

  const handleCategory = async () => {
    if (action.type === "Create") {
      if (category) {
        const data = {
          title: category,
        }
        const response = await createCategory(data, dispatch, setLoading)
        if (response.status === "error") {
          toast.error(response.res.payload)
          setLoading(false)
          return
        } else {
          toast.success(response.message.payload.message)
          tog_xlarge()
          window.location.reload()
          return
        }
      } else {
        toast.error("category is required")
      }
    }
    if (action.type === "Update") {
      if (category) {
        const data = {
          id: action.id,
          title: category,
        }
        const response = await updateCategory(data, dispatch, setLoading)
        if (response.status === "error") {
          toast.error(response.res.payload)
          setLoading(false)
          return
        } else {
          toast.success(response.message.payload.message)
          tog_xlarge()
          window.location.reload()
          return
        }
      } else {
        toast.error("category is required")
      }
    }
    if (action.type === "Delete") {
      const data = {
        id: action.id,
      }
      const response = await categoryDelete(data, dispatch, setLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
        setLoading(false)
        return
      } else {
        toast.success(response.message.payload.message)
        tog_xlarge()
        window.location.reload()
        return
      }
    }
  }

  useEffect(() => {
    setCategory(action.text || "")
  }, [action])

  return (
    <div>
      <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="sl">
        <ModalHeader className="mt-0" toggle={tog_xlarge}>
          {action.type} Category
        </ModalHeader>
        <ModalBody>
          <div className="p-3">
            {action.type === "Delete" ? (
              <div>Are you sure you want to delete {action.text} category?</div>
            ) : (
              <input
                type="text"
                value={category}
                placeholder="Title"
                className="form-control"
                onChange={e => setCategory(e.target.value)}
              />
            )}
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {action.type === "Delete" ? (
                <>
                  {loading ? (
                    <Button color="danger">
                      <ButtonLoader
                        size={30}
                        color1={"white"}
                        color2={"white"}
                      />
                    </Button>
                  ) : (
                    <Button color="danger" onClick={handleCategory}>
                      {action.type}
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {loading ? (
                    <Button color="primary">
                      <ButtonLoader
                        size={30}
                        color1={"white"}
                        color2={"white"}
                      />
                    </Button>
                  ) : (
                    <Button color="primary" onClick={handleCategory}>
                      {action.type}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default CreateCategory
