import React, { useState, useEffect, useContext } from "react"
import { Card, CardBody, CardTitle, Row, Col, Button } from "reactstrap"
import { Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TranactionsComponent from "components/transactions/Transactions"
import ViewTransaction from "components/transactions/ViewTransaction"
//
import moment from "moment"

const Tranactions = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Deonicode" breadcrumbItem="Transactions" />
          <TranactionsComponent heading={"Transactions"} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Tranactions
