import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit"
import axios from "axios"

const url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/withdrawals`
const userToken = localStorage.getItem("deonicode-admin")

const initialState = {
  withdrawals: [],
}

export const createRequest = createAsyncThunk(
  "withdrawals/createRequest",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${url}/withdrawal-request`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const userRequest = createAsyncThunk(
  "withdrawals/userRequest",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`${url}/requests/${data.userId}`,{
        headers:{
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getAllRequest = createAsyncThunk(
  "withdrawals/getAllRequest",
  async thunkAPI => {
    try {
      const response = await axios.get(`${url}/all-requests`,{
        headers:{
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateStatus = createAsyncThunk(
  "withdrawals/updateStatus",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${url}/update-status/${data.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const authSlice = createSlice({
  name: "withdrawals",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(userRequest.fulfilled, (state, action) => {
        state.withdrawals = action.payload
      })
      .addCase(getAllRequest.fulfilled, (state, action) => {
        state.withdrawals = action.payload.requests
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        const id = action.payload.updated._id
        const status = action.payload.updated.status
        let currentState = current(state).withdrawals
        let newArray = currentState.map(item => {
          if (item._id === id) {
            return { ...item, status: status }
          }
          return item
        })
        state.withdrawals = newArray
      })
  },
})

export const { logout } = authSlice.actions

export default authSlice.reducer
