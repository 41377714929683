import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit"
import axios from "axios"

const url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/category`
const userToken = localStorage.getItem("deonicode-admin")


const initialState = {
  categories: [],
  category: null,
}
export const create = createAsyncThunk(
  "category/create",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${url}/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const categories = createAsyncThunk(
  "category/categories",
  async thunkAPI => {
    try {
      const response = await axios.get(`${url}/categories`,{
        headers:{
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const category = createAsyncThunk(
  "category/category",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`${url}/category/${data.id}`,{
        headers:{
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const update = createAsyncThunk(
  "category/update",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${url}/update-category/${data.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteCategory = createAsyncThunk(
  "category/deleteCategory",
  async (data, thunkAPI) => {
    try {
      const response = await axios.delete(`${url}/delete-category/${data.id}`,{
        headers:{
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const categorySlice = createSlice({
  name: "category",
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(create.fulfilled, (state, action) => {
        let currentState = current(state).categories.categories
        currentState = [action.payload.response, ...currentState]
        state.categories.categories = currentState
      })
      .addCase(categories.fulfilled, (state, action) => {
        state.categories = action.payload
      })
      .addCase(category.fulfilled, (state, action) => {
        state.category = action.payload
      })
      .addCase(update.fulfilled, (state, action) => {
        const id = action.payload.updated._id
        const title = action.payload.updated.title
        const slug = action.payload.updated.slug
        let currentState = current(state).categories.categories
        let newArray = currentState.map(item => {
          if (item._id === id) {
            return { ...item, title: title, slug: slug }
          }
          return item
        })
        state.categories.categories = newArray
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        let currentState = state.categories.categories.filter(
          item => item._id != action.payload.response.id
        )
        state.categories.categories = currentState
      })
  },
})

export const {} = categorySlice.actions

export default categorySlice.reducer
