import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/admin`
const userToken = localStorage.getItem("deonicode-admin")


const initialState = {
  user: null,
}

export const login = createAsyncThunk("auth/login", async (data, thunkAPI) => {
  try {
    const response = await axios.post(`${url}/login`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    if (response.data) {
      localStorage.setItem(
        "deonicode-admin",
        JSON.stringify(response.data.token)
      )
    }
    return response.data
  } catch (error) {
    const message =
      (error.message && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const updateDetails = createAsyncThunk(
  "teachers/updateDetails",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${url}/update/${data.id}`,
        data.values,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      if (response.data) {
        localStorage.setItem(
          "deonicode-admin",
          JSON.stringify(response.data.token)
        )
      }
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updatePassword = createAsyncThunk(
  "teachers/updatePassword",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${url}/update-password/${data.id}`,
        data.values,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: state => {
      state.user = null
      localStorage.removeItem("deonicode-admin")
    },
  },
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload.token
    })
  },
})

export const { logout } = authSlice.actions

export default authSlice.reducer
