import React, { useState, useEffect, useContext } from "react"
import { Card, CardBody, CardTitle, Row, Col, Button } from "reactstrap"
import ViewTransaction from "components/transactions/ViewTransaction"
//
import moment from "moment"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { AuthContext } from "context/authContext/authContext"
import { getAllUsers } from "helpers/helpers/users"
import { allCourses } from "helpers/helpers/courses"
import { getAllTeachers } from "helpers/helpers/Teachers"
import {
  getAllTransactions,
  getAllInstructorTransactions,
  conversionRate,
} from "helpers/helpers/transactions"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const TranactionsComponent = ({ heading }) => {
  const dispatch = useDispatch()
  const { userId, role } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [courses, setCourses] = useState([])
  const [allusers, setAllUsers] = useState([])
  const [teachers, setTeachers] = useState([])
  const [transactions, setTransactions] = useState([])
  const [transactionDetails, setTransactionDetails] = useState(null)
  const [currencyConversion, setCurrencyConversion] = useState("")

  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const tog_xlarge = item => {
    setTransactionDetails(item)
    setmodal_xlarge(!modal_xlarge)
    removeBodyCss()
  }

  const getTransactions = async () => {
    if (role === "admin") {
      const response = await getAllTransactions(dispatch, setLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
      } else {
        setTransactions(response.message.payload.transactions)
        toast.success(response.message.payload.message)
      }
    } else {
      const data = {
        id: userId,
      }
      const response = await getAllInstructorTransactions(
        data,
        dispatch,
        setLoading
      )
      if (response.status === "error") {
        toast.error(response.res.payload)
      } else {
        setTransactions(response.message.payload.transactions)
        toast.success(response.message.payload.message)
      }
    }
  }

  const getCourses = async () => {
    const response = await allCourses(dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    } else {
      setCourses(response.message.payload.courses)
      toast.success(response.message.payload.message)
    }
  }

  const allUsers = async () => {
    const response = await getAllUsers(dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    } else {
      setAllUsers(response.message.payload.users)
      toast.success(response.message.payload.message)
    }
  }

  const allTeachers = async () => {
    const response = await getAllTeachers(dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    } else {
      setTeachers(response.message.payload.users)
      toast.success(response.message.payload.message)
    }
  }

  const getConversionRate = async () => {
    const response = await conversionRate(dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    } else {
      const result = JSON.parse(response.message.payload.data)
      setCurrencyConversion(result.result)
    }
  }

  function filterFunction(textInput) {
    // Declare variables
    var input, filter, table, tr, td, i, txtValue
    input = textInput
    filter = input.value.toUpperCase()
    table = document.getElementById("txnTable")
    tr = table.getElementsByTagName("tr")

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[3]
      if (td) {
        txtValue = td.textContent || td.innerText
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = ""
        } else {
          tr[i].style.display = "none"
        }
      }
    }
  }

  useEffect(() => {
    getConversionRate()
  }, [])

  useEffect(() => {
    if (userId !== "") {
      getCourses()
      allUsers()
      allTeachers()
      getTransactions()
    }
  }, [userId])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <CardTitle className="mb-4">{heading}</CardTitle>
            </Col>
            <Col xl={2}>
              <CardTitle className="mb-4">
                <input
                  type="text"
                  placeholder="search course"
                  className="form-control"
                  onChange={e => filterFunction(e.target)}
                />
              </CardTitle>
            </Col>
          </Row>
          <div className="table-responsive">
            <table
              className="table align-middle table-nowrap mb-0"
              id="txnTable"
            >
              <thead className="table-light">
                <tr>
                  <th className="align-middle">ID</th>
                  <th className="align-middle">User</th>
                  <th className="align-middle">Course</th>
                  <th className="align-middle">Date</th>
                  <th className="align-middle">Amount</th>
                  <th className="align-middle">Payment Status</th>
                  <th className="align-middle">Payment Method</th>
                  <th className="align-middle">View Details</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={9} className="text-center">
                      <ButtonLoader
                        size={40}
                        color1={"black"}
                        color2={"black"}
                      />
                    </td>
                  </tr>
                ) : transactions && transactions.length > 0 ? (
                  transactions.map((transaction, key) => {
                    const user = allusers.filter(
                      u => u._id === transaction.userId
                    )
                    const course = courses.filter(
                      c => c._id === transaction.courseId
                    )
                    const tutor = teachers.filter(
                      t => t._id === transaction.instructorId
                    )
                    return (
                      <tr key={"_tr_" + key}>
                        <td>
                          <div className="font-size-16">{key + 1}</div>
                        </td>
                        <td>{user[0]?.username}</td>
                        <td>{course[0]?.title}</td>
                        <td>
                          {moment(transaction.createdAt).format(
                            "DD-MM-YYYY HH:mm"
                          )}
                        </td>
                        <td>
                          $
                          {Math.round(transaction?.amount / currencyConversion)}
                        </td>
                        <td>
                          <span className="badge bg-success p-1">
                            {transaction.status}
                          </span>
                        </td>
                        <td>{transaction.paymentMethod}</td>
                        <td>
                          <Button
                            onClick={() =>
                              tog_xlarge({
                                transaction,
                                user,
                                course,
                                tutor,
                              })
                            }
                            color="primary"
                            size="sm"
                          >
                            View Detail
                          </Button>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={9} className="text-center">
                      No Transaction Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
      <ViewTransaction
        modal_xlarge={modal_xlarge}
        tog_xlarge={tog_xlarge}
        details={transactionDetails}
      />
    </React.Fragment>
  )
}

export default TranactionsComponent
