import React, { useState } from "react"
import { toast } from "react-toastify"
import { Col, Modal, ModalBody, ModalHeader, Row, Button } from "reactstrap"
import { useDispatch } from "react-redux"
import { createLesson, createLoomLesson } from "helpers/helpers/lesson"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const CreateLesson = ({ modal_xlarge, tog_xlarge, action, chapterId }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [lessonTitle, setLessonTitle] = useState("")
  const [videoFile, setVideoFile] = useState(null)
  const [duration, setDuration] = useState(0)
  // Loom state variables
  const [isLoom, setIsLoom] = useState(false)
  const [loomLink, setLoomLink] = useState("")

  const handleCheckbox = e => {
    setIsLoom(e.target.checked)
  }

  const handleLessonCreation = async () => {
    if (isLoom) {
      if (chapterId && lessonTitle && loomLink && duration) {
        const data = {
          chapterId,
          title: lessonTitle,
          loomLink,
          duration,
        }

        const response = await createLoomLesson(data, dispatch, setLoading)
        if (response.status === "error") {
          toast.error(response.res.payload)
        } else {
          toast.success(response.message.payload.message)
          tog_xlarge()
          window.location.reload()
        }
      } else {
        toast.error("All fields are required")
      }
    } else {
      if (chapterId && lessonTitle && videoFile && duration) {
        let data = new FormData()
        data.append("chapterId", chapterId)
        data.append("title", lessonTitle)
        data.append("lessonVideo", videoFile)
        data.append("duration", duration)

        const response = await createLesson(data, dispatch, setLoading)
        if (response.status === "error") {
          toast.error(response.res.payload)
        } else {
          toast.success(response.message.payload.message)
          tog_xlarge()
          window.location.reload()
        }
      } else {
        toast.error("All fields are required")
      }
    }
  }

  return (
    <div>
      <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="sl">
        <ModalHeader
          className="mt-0 d-flex justify-content-between"
          toggle={tog_xlarge}
        >
          <span>{action} Lesson</span>
          <div className="my-2">
            Loom lesson?{" "}
            <input
              type="checkbox"
              className="form-check-input"
              onChange={e => handleCheckbox(e)}
            />
            {" yes "}
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="p-3">
            <input
              type="text"
              placeholder="Lesson Title *"
              className="form-control"
              onChange={e => setLessonTitle(e.target.value)}
            />
            <br />
            {isLoom ? (
              <input
                type="text"
                placeholder="Loom video link *"
                className="form-control"
                onChange={e => setLoomLink(e.target.value)}
              />
            ) : (
              <>
                <p className="h6">Lesson video: *</p>
                <input
                  type="file"
                  accept="video/*"
                  placeholder="Video file"
                  className="form-control"
                  onChange={e => setVideoFile(e.target.files[0])}
                />
              </>
            )}
            <br />
            <input
              type="number"
              placeholder="Duration in minutes *"
              className="form-control"
              onChange={e => setDuration(e.target.value)}
            />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {loading ? (
                <Button color="primary">
                  <ButtonLoader size={30} color1={"white"} color2={"white"} />
                </Button>
              ) : (
                <Button color="primary" onClick={handleLessonCreation}>
                  {action}
                </Button>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default CreateLesson
