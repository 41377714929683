import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/q-and-a`
const userToken = localStorage.getItem("deonicode-admin")

const initialState = {
  users: [],
  questions: [],
}

export const getMentorshipUsers = createAsyncThunk(
  "mentorship/getMentorshipUsers",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `${url}/questions-users/${data.id}/${data.courseId}`,{
          headers:{
            Authorization: `Bearer ${userToken}`,
          }
        }
      )
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getMentorshipQuestions = createAsyncThunk(
  "mentorship/getMentorshipQuestions",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `${url}/questions/${data.userId}/${data.courseId}`,{
          headers:{
            Authorization: `Bearer ${userToken}`,
          }
        }
      )
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const createQuestion = createAsyncThunk(
  "mentorship/createQuestion",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${url}/create-message`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const mentorshipSlice = createSlice({
  name: "mentorship",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getMentorshipUsers.fulfilled, (state, action) => {
        state.users = action.payload
      })
      .addCase(getMentorshipQuestions.fulfilled, (state, action) => {
        state.questions = action.payload
      })
  },
})

export const {} = mentorshipSlice.actions

export default mentorshipSlice.reducer
