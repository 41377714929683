import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
import user1 from "../../../assets/images/users/placeholder.jpg"
import { AuthContext } from "context/authContext/authContext"
import { logoutAdmin } from "helpers/helpers/authentication"
import { getSingleTeacher } from "helpers/helpers/Teachers"
import { useDispatch } from "react-redux"

const ProfileMenu = props => {
  const dispatch = useDispatch()
  const { userId, setUserId, role } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [menu, setMenu] = useState(false)
  const [avatar, setAvatar] = useState(null)
  const instructor = useSelector(state => state.teachers.teacher)

  const instructorDetails = async () => {
    const data = {
      id: userId,
    }
    await getSingleTeacher(data, dispatch, setLoading)
  }

  useEffect(() => {
    if (userId !== "undefined" && role === "instructor") {
      instructorDetails()
    }
  }, [userId])

  useEffect(() => {
    if (instructor !== null) {
      setAvatar(instructor?.avatar?.doc)
    }
  }, [instructor])

  const handleLogout = () => {
    const userConfirmed = window.confirm("Do you want to proceed?")
    if (userConfirmed) {
      logoutAdmin(dispatch)
      setUserId("")
      setRole("")
      setEmail("")
      setAuthenticated(false)
    } else {
      return
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={avatar || user1}
            alt="Header Avatar"
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link className="dropdown-item" to="/profile">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}
          </Link>

          <div className="dropdown-divider" />
          <Link className="dropdown-item" onClick={handleLogout}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
