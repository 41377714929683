import {
  getTransactions,
  getInstructorTransactions,
  getConversionRate,
} from "store/reduxToolkit/reducers/transactionReducer"

export const getAllTransactions = async (dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(getTransactions()).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const getAllInstructorTransactions = async (
  data,
  dispatch,
  setLoading
) => {
  setLoading(true)
  try {
    const response = await dispatch(getInstructorTransactions(data)).then(
      res => {
        if (res.meta.requestStatus === "rejected") {
          return { status: "error", message: "response error", res }
        } else {
          return { status: "success", message: res }
        }
      }
    )
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const conversionRate = async (dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(getConversionRate()).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}
