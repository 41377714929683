import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap"
import { useDispatch } from "react-redux"
import { newTemplate, templateUpdate } from "helpers/helpers/emails"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const CreateTemplate = ({ modal_xlarge, tog_xlarge, action, updateItem }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState("")
  const [message, setMessage] = useState("")

  useEffect(() => {
    if (action === "Update") {
      setTitle(updateItem?.title)
      setMessage(updateItem?.message)
    }
  }, [updateItem])

  const templateHandler = async () => {
    try {
      if (action === "Update") {
        if (title && message) {
          const data = {
            id: updateItem._id,
            title,
            message,
          }
          const response = await templateUpdate(data, dispatch, setLoading)
          if (response.status === "error") {
            toast.error(response.res.payload)
          } else {
            toast.success(response.message.payload.message)
          }
        } else {
          toast.error("All fields are required")
        }
      } else {
        if (title && message) {
          const data = {
            title,
            message,
          }
          const response = await newTemplate(data, dispatch, setLoading)
          if (response.status === "error") {
            toast.error(response.res.payload)
          } else {
            toast.success(response.message.payload.message)
          }
        } else {
          toast.error("All fields are required")
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="sl">
        <ModalHeader className="mt-0" toggle={tog_xlarge}>
          {action} Template
        </ModalHeader>
        <ModalBody>
          <div className="p-3">
            <input
              type="text"
              placeholder="Title"
              className="form-control"
              value={title || ""}
              onChange={e => setTitle(e.target.value)}
            />
            <br />
            <textarea
              type="text"
              placeholder="Body"
              className="form-control"
              rows="10"
              value={message || ""}
              onChange={e => setMessage(e.target.value)}
            />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {loading ? (
                <Button color="primary">
                  <ButtonLoader size={30} color1={"white"} color2={"white"} />
                </Button>
              ) : (
                <Button color="primary" onClick={templateHandler}>
                  {action}
                </Button>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default CreateTemplate
