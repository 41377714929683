import {
  login,
  logout,
  updatePassword,
  updateDetails,
} from "../../store/reduxToolkit/reducers/authReducer"

export const loginAdmin = async (data, dispatch, setLoading) => {
  setLoading(true)
  if (data.email && data.password) {
    try {
      const response = await dispatch(login(data)).then(res => {
        if (res.meta.requestStatus === "rejected") {
          return { status: "error", message: "response error", res }
        } else {
          return { status: "success", message: res }
        }
      })
      setLoading(false)
      return response
    } catch (error) {
      setLoading(false)
      return { status: "error", message: "catch error", error }
    }
  } else {
    setLoading(false)
    return { status: "error", message: "all fields are required" }
  }
}

export const updateAdminPassword = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(updatePassword(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const updateAdminDetails = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(updateDetails(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const logoutAdmin = async dispatch => {
  try {
    await dispatch(logout())
    return response
  } catch (error) {
    return { status: "error", message: "catch error", error }
  }
}
