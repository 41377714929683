import React, { useEffect, useState, useContext } from "react"
import { Button } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import {
  newDiscount,
  getDiscount,
  discountUpdate,
  discountDelete,
  //
  getPriceRange,
  newPriceRange,
  //
  newPercentage,
  getPercentage,
  //
  newAffiliatePercentage,
  getAffiliatePercentage,
} from "helpers/helpers/settings"
import { AuthContext } from "context/authContext/authContext"
import moment from "moment"

const Settings = () => {
  const dispatch = useDispatch()
  const {
    discountId,
    discountAmount,
    expiryDate,
    expiredToken,
    setDiscountAmount,
    setExpiryDate,
  } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [priceRangeLoading, setPriceRangeLoading] = useState(false)
  const [percentageLoading, setPercentageLoading] = useState(false)
  const [affiliateLoading, setAffiliateLoading] = useState(false)
  //
  const [amount, setAmount] = useState("")
  const [duration, setDuration] = useState("")
  //
  const [minPrice, setMinPrice] = useState(0)
  const [maxPrice, setMaxPrice] = useState(0)
  const [priceRange, setPriceRange] = useState(null)
  //
  const [percentage, setPercentage] = useState(0)
  const [deonicodePerc, setDeonicodePerc] = useState(null)
  //
  const [affPercentage, setAffPercentage] = useState(0)
  const [affiliatePerc, setAffiliatePerc] = useState(null)

  useEffect(() => {
    setAmount(discountAmount)
  }, [discountAmount])

  const discountHandler = async () => {
    try {
      if (amount && duration) {
        const date1 = Date.now()
        const date2 = new Date(duration).getTime()
        const newDuration = Math.ceil((date2 - date1) / (1000 * 60 * 60 * 24))
        if (discountAmount) {
          const data = {
            id: discountId,
            amount,
            duration: newDuration,
          }
          const response = await discountUpdate(data, dispatch, setLoading)
          if (response.status === "error") {
            toast.error(response.res.payload)
          } else {
            toast.success(response.message.payload.message)
          }
        } else {
          const data = {
            amount,
            duration: newDuration,
          }
          const response = await newDiscount(data, dispatch, setLoading)
          if (response.status === "error") {
            toast.error(response.res.payload)
          } else {
            toast.success(response.message.payload.message)
          }
        }
      } else {
        toast.error("All fields are required")
      }
    } catch (error) {
      toast.error("An error occured")
    }
  }

  const getCoursesDiscount = async () => {
    try {
      const response = await getDiscount(dispatch, setLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
      }
    } catch (error) {
      toast.error("An error occured")
    }
  }

  const deleteDiscount = async () => {
    try {
      const data = {
        id: discountId,
      }
      const response = await discountDelete(data, dispatch, setDeleteLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
      } else {
        setDiscountAmount("")
        setExpiryDate(null)
        toast.success(response.message.payload.message)
      }
    } catch (error) {
      toast.error("An error occured")
    }
  }

  const priceRangeHandler = async () => {
    try {
      if (minPrice && maxPrice) {
        if (parseInt(maxPrice) < parseInt(minPrice)) {
          toast.error("Minimum price is greater maximum price")
          return
        }
        const data = {
          id: priceRange !== "" ? priceRange._id : 1,
          minPrice,
          maxPrice,
        }
        const response = await newPriceRange(
          data,
          dispatch,
          setPriceRangeLoading
        )
        if (response.status === "error") {
          toast.error(response.res.payload)
        } else {
          setPriceRange(response.message.payload.response[0])
          toast.success(response.message.payload.message)
        }
      } else {
        toast.error("Both fields are required")
        return
      }
    } catch (error) {
      toast.error("An error occured")
    }
  }

  const getPriceRangeHandler = async () => {
    try {
      const response = await getPriceRange(dispatch, setPriceRangeLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
        return
      }
      setPriceRange(response.message.payload)
      return
    } catch (error) {
      toast.error("An error occured")
    }
  }

  const percentageHandler = async () => {
    try {
      if (percentage) {
        const data = {
          id: deonicodePerc !== null ? deonicodePerc._id : 1,
          amount: percentage,
        }
        const response = await newPercentage(
          data,
          dispatch,
          setPercentageLoading
        )
        if (response.status === "error") {
          toast.error(response.res.payload)
        } else {
          setDeonicodePerc(response.message.payload.response[0])
          toast.success(response.message.payload.message)
        }
      } else {
        toast.error("Percentage should be greater than zero")
        return
      }
    } catch (error) {
      toast.error("An error occured")
    }
  }

  const getPercentageHandler = async () => {
    try {
      const response = await getPercentage(dispatch, setPercentageLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
        return
      }
      setDeonicodePerc(response.message.payload)
      return
    } catch (error) {
      toast.error("An error occured")
    }
  }

  const affiliatePercentageHandler = async () => {
    try {
      if (affPercentage) {
        const data = {
          id: affiliatePerc !== null ? affiliatePerc._id : 1,
          percentage: affPercentage,
        }
        const response = await newAffiliatePercentage(
          data,
          dispatch,
          setAffiliateLoading
        )
        if (response.status === "error") {
          toast.error(response.res.payload)
        } else {
          setAffiliatePerc(response.message.payload.response[0])
          toast.success(response.message.payload.message)
        }
      } else {
        toast.error("Percentage should be greater than zero")
        return
      }
    } catch (error) {
      toast.error("An error occured")
    }
  }

  const getAffiliatePercentageHandler = async () => {
    try {
      const response = await getAffiliatePercentage(
        dispatch,
        setAffiliateLoading
      )
      if (response.status === "error") {
        toast.error(response.res.payload)
        return
      }
      setAffiliatePerc(response.message.payload)
      return
    } catch (error) {
      toast.error("An error occured")
    }
  }

  useEffect(() => {
    getCoursesDiscount()
    getPriceRangeHandler()
    getPercentageHandler()
    getAffiliatePercentageHandler()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            // maintitle="Deonicode"
            title="Deonicode"
            breadcrumbItem="Settings"
          />
          <div
            style={{
              width: "100%",
            }}
          >
            <div>
              <p className="fw-bold">Black Friday</p>
              {expiredToken ? (
                <div className="text-danger mb-2">No Active Discount</div>
              ) : discountAmount ? (
                <div className="text-success mb-2">Discount Active</div>
              ) : (
                <div className="text-warning mb-2">No Active Discount</div>
              )}
              <div className="text-danger mb-2">
                Expires in: {moment(expiryDate).format("DD-MM-YYYY HH:mm")}
              </div>
              <div>
                <div className="d-flex">
                  <div className="m-1">
                    <input
                      placeholder="Discount Amount(%)"
                      type="number"
                      className="form-control"
                      value={amount}
                      onChange={e => setAmount(e.target.value)}
                    />
                  </div>
                  <div className="m-1">
                    <input
                      placeholder="Discount Amount(%)"
                      type="date"
                      className="form-control"
                      value={duration}
                      onChange={e => setDuration(e.target.value)}
                    />
                  </div>
                </div>
                <br />
                <Button color="success m-2" onClick={discountHandler}>
                  {loading ? "Loading..." : "Save"}
                </Button>
                <button
                  type="button"
                  className="btn btn-danger waves-effect waves-light"
                  onClick={deleteDiscount}
                >
                  <i className="fa fa-trash-alt"></i>
                  {deleteLoading ? "Deleting..." : ""}
                </button>
              </div>
            </div>
            <br />
            <div>
              <p className="fw-bold">Course Price Range</p>
              <ul>
                <li>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>Minimum Price: ${priceRange?.minPrice}</p>
                  </div>
                </li>
                <li>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>Maximum Price: ${priceRange?.maxPrice}</p>
                  </div>
                </li>
              </ul>
              <div className="d-flex">
                <div className="m-1">
                  <input
                    placeholder="Min Price"
                    type="number"
                    className="form-control"
                    onChange={e => setMinPrice(e.target.value)}
                  />
                </div>
                <div className="m-1">
                  <input
                    placeholder="Max Price"
                    type="number"
                    className="form-control"
                    onChange={e => setMaxPrice(e.target.value)}
                  />
                </div>
              </div>
              <br />
              <Button color="primary" onClick={priceRangeHandler}>
                {priceRangeLoading ? "Loading..." : "Submit"}
              </Button>
            </div>
            <div className="my-5">
              <p className="fw-bold">Platform Percentage</p>
              <ul>
                <li>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>Percentage: {deonicodePerc?.amount}%</p>
                  </div>
                </li>
              </ul>
              <div className="d-flex">
                <div className="m-1">
                  <input
                    placeholder="e.g 10"
                    type="number"
                    className="form-control"
                    onChange={e => setPercentage(e.target.value)}
                  />
                </div>
              </div>
              <br />
              <Button color="primary" onClick={percentageHandler}>
                {percentageLoading ? "Loading..." : "Submit"}
              </Button>
            </div>
            <div className="my-5">
              <p className="fw-bold">Affiliate Percentage</p>
              <ul>
                <li>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>Percentage: {affiliatePerc?.percentage}%</p>
                  </div>
                </li>
              </ul>
              <div className="d-flex">
                <div className="m-1">
                  <input
                    placeholder="e.g 10"
                    type="number"
                    className="form-control"
                    onChange={e => setAffPercentage(e.target.value)}
                  />
                </div>
              </div>
              <br />
              <Button color="primary" onClick={affiliatePercentageHandler}>
                {affiliateLoading ? "Loading..." : "Submit"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Settings
