import React, { useState, useEffect } from "react"
// import { Row, Col, Card, CardBody } from "reactstrap"
// import { Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Template from "components/Email/Template"
import CreateTemplate from "components/Email/CreateTemplate"
//
import { useDispatch, useSelector } from "react-redux"
import { getTemplates } from "helpers/helpers/emails"
import { toast } from "react-toastify"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const Templates = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const templates = useSelector(state => state.mails.templates)

  const getAllTemplates = async () => {
    try {
      const response = await getTemplates(dispatch, setLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getAllTemplates()
  }, [])

  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [action, setAction] = useState("")

  const tog_xlarge = () => {
    setAction("Edit")
    setmodal_xlarge(!modal_xlarge)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            btnShow={true}
            action="template"
            btnText="Create Template"
            title="Deonicode"
            breadcrumbItem="Email Templates"
          />
          {loading ? (
            <ButtonLoader size={40} color1={"black"} color2={"black"} />
          ) : templates.length > 0 ? (
            templates.map((template, index) => (
              <Template template={template} key={index} />
            ))
          ) : (
            <div className="text-center">No Templates Found</div>
          )}
        </div>
      </div>
      <CreateTemplate
        action={action}
        modal_xlarge={modal_xlarge}
        tog_xlarge={tog_xlarge}
      />
    </React.Fragment>
  )
}

export default Templates
