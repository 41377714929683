import {
  getTeachers,
  getTeacher,
  updateTeacherStatus,
  uploadProfileImage,
  updateDetails,
  updateSocialDetails,
  updatePassword,
  generateAffiliateLink
} from "../../store/reduxToolkit/reducers/teachersReducers"

export const getAllTeachers = async (dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(getTeachers()).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const getSingleTeacher = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(getTeacher(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const updateProfileImage = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(uploadProfileImage(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const updateInstructorStatus = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(updateTeacherStatus(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const updateProfileDetails = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(updateDetails(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const updateSocials = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(updateSocialDetails(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const updateTutorPassword = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(updatePassword(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const getAffiliateLink = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(generateAffiliateLink(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}
