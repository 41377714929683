import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem, Button } from "reactstrap"
import CreateCourse from "components/Course/CreateCourse"
import CreateChapter from "components/Course/CreateChapter"
import CreateTemplate from "components/Email/CreateTemplate"
import CreateAffiliate from "components/Affiliates/CreateAffiliate"
import CreateCategory from "components/category/CreateCategory"

const Breadcrumb = ({
  action,
  maintitle,
  title,
  breadcrumbItem,
  btnShow,
  btnText,
}) => {
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [modal_chapter, setmodal_chapter] = useState(false)
  const [modal_category, setmodal_category] = useState(false)
  const [modal_template, setmodal_template] = useState(false)
  const [modal_affiliate, setmodal_affiliate] = useState(false)
  const [_action, setAction] = useState("")
  const [templateAction, setTemplateAction] = useState("")
  const [chapterAction, setChapterAction] = useState("")
  const [categoryAction, setCategoryAction] = useState("")
  const [affiliateAction, setAffiliateAction] = useState("")

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const tog_xlarge = () => {
    setAction("Create")
    setmodal_xlarge(!modal_xlarge)
    removeBodyCss()
  }

  const tog_chapter = () => {
    setChapterAction({ type: "Create" })
    setmodal_chapter(!modal_chapter)
    removeBodyCss()
  }
  const tog_category = () => {
    setCategoryAction({ type: "Create" })
    setmodal_category(!modal_category)
    removeBodyCss()
  }
  const tog_template = () => {
    setTemplateAction("Create")
    setmodal_template(!modal_template)
    removeBodyCss()
  }
  const tog_affiliate = () => {
    setAffiliateAction("Create")
    setmodal_affiliate(!modal_affiliate)
    removeBodyCss()
  }

  const handleCreate = () => {
    if (action === "course") {
      tog_xlarge()
    }
    if (action === "chapter") {
      tog_chapter()
    }
    if (action === "category") {
      tog_category()
    }
    if (action === "template") {
      tog_template()
    }
    if (action === "affiliate") {
      tog_affiliate()
    }
  }

  return (
    <>
      <Row className="align-items-center">
        <Col sm={6}>
          <div className="page-title-box">
            <h4 className="font-size-18">{breadcrumbItem}</h4>
            <ol className="breadcrumb mb-0">
              {maintitle ? (
                <>
                  <BreadcrumbItem>
                    <Link to="/#">{maintitle}</Link>
                  </BreadcrumbItem>
                </>
              ) : (
                ""
              )}
              <BreadcrumbItem>
                <Link to="/#">{title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>{breadcrumbItem}</BreadcrumbItem>
            </ol>
          </div>
        </Col>
        {btnShow && (
          <Col sm={6}>
            <div className="float-end d-none d-md-block">
              <Button onClick={handleCreate} color="primary">
                <i className="mdi mdi-plus me-2"></i> {btnText}
              </Button>
            </div>
          </Col>
        )}
      </Row>
      <CreateCourse
        action={_action}
        modal_xlarge={modal_xlarge}
        tog_xlarge={tog_xlarge}
        setmodal_xlarge
      />
      <CreateChapter
        modal_xlarge={modal_chapter}
        tog_xlarge={tog_chapter}
        action={chapterAction}
      />
      <CreateCategory
        modal_xlarge={modal_category}
        tog_xlarge={tog_category}
        action={categoryAction}
      />
      <CreateTemplate
        action={templateAction}
        modal_xlarge={modal_template}
        tog_xlarge={tog_template}
      />
      <CreateAffiliate
        action={affiliateAction}
        modal_xlarge={modal_affiliate}
        tog_xlarge={tog_affiliate}
      />
    </>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  action: PropTypes.string,
}

export default Breadcrumb
