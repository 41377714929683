import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/course`
const userToken = localStorage.getItem("deonicode-admin")

const initialState = {
  courses: [],
  course: null,
}

export const create = createAsyncThunk(
  "course/create",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${url}/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getAllCourses = createAsyncThunk("course/read", async thunkAPI => {
  try {
    const response = await axios.get(`${url}/courses`,{
      headers:{
        Authorization: `Bearer ${userToken}`,
      }
    })
    return response.data
  } catch (error) {
    const message =
      (error.message && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const readOne = createAsyncThunk(
  "course/readOne",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`${url}/course/${data.id}`,{
        headers:{
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const instructorCourses = createAsyncThunk(
  "course/instructorCourses",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`${url}/instructor-courses/${data.id}`,{
        headers:{
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const update = createAsyncThunk(
  "course/update",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${url}/update-course/${data.id}`,
        data.formData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateStatus = createAsyncThunk(
  "course/updateStatus",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(`${url}/update-status/${data.id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteCourse = createAsyncThunk(
  "course/deleteCourse",
  async (data, thunkAPI) => {
    try {
      const response = await axios.delete(`${url}/delete-course/${data.id}`,{
        headers:{
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(create.fulfilled, (state, action) => {
        state.course = action.payload
      })
      .addCase(getAllCourses.fulfilled, (state, action) => {
        state.courses = action.payload
      })
      .addCase(readOne.fulfilled, (state, action) => {
        state.course = action.payload
      })
  },
})

export const { logout } = courseSlice.actions

export default courseSlice.reducer
