import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/announcement`
const userToken = localStorage.getItem("deonicode-admin")

const initialState = {
  announcements: [],
}

export const allAnnouncement = createAsyncThunk(
  "announcement/allAnnouncement",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`${url}/announcements/${data.courseId}`,{
        headers:{
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const postAnnouncement = createAsyncThunk(
  "announcement/postAnnouncement",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${url}/post-announcement`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const announcementSlice = createSlice({
  name: "announcement",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(allAnnouncement.fulfilled, (state, action) => {
      state.announcements = action.payload
    })
  },
})

export const {} = announcementSlice.actions

export default announcementSlice.reducer
