import {
  createRequest,
  userRequest,
  getAllRequest,
  updateStatus,
} from "../../store/reduxToolkit/reducers/withdrawalReducer"

export const newRequest = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(createRequest(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const requestByUser = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(userRequest(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const allRequests = async (dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(getAllRequest()).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const updateRequest = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(updateStatus(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}
