import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/chapter`
const userToken = localStorage.getItem("deonicode-admin")


const initialState = {
  chapters: [],
  chapter: null,
}
export const create = createAsyncThunk(
  "chapter/create",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${url}/create`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const courseChapters = createAsyncThunk(
  "chapter/read",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`${url}/chapters/${data.id}`,{
        headers:{
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const readOne = createAsyncThunk(
  "chapter/readOne",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`${url}/chapter/${data.id}`,{
        headers:{
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const update = createAsyncThunk(
  "chapter/update",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${url}/update-chapter/${data.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteChapter = createAsyncThunk(
  "chapter/deleteChapter",
  async (data, thunkAPI) => {
    try {
      const response = await axios.delete(`${url}/delete-chapter/${data.id}`,{
        headers:{
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const chapterSlice = createSlice({
  name: "chapter",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(create.fulfilled, (state, action) => {
        state.chapter = action.payload
      })
      .addCase(courseChapters.fulfilled, (state, action) => {
        state.chapters = action.payload
      })
      .addCase(readOne.fulfilled, (state, action) => {
        state.chapter = action.payload
      })
  },
})

export const { logout } = chapterSlice.actions

export default chapterSlice.reducer
