import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/settings`
const userToken = localStorage.getItem("deonicode-admin")

const initialState = {
  discount: null,
  priceRange: null,
  deonicodePercentage: null,
  affiliatePercentage: null,
}

export const createDiscount = createAsyncThunk(
  "discount/createDiscount",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${url}/create-discount`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      if (response) {
        localStorage.setItem(
          "deonicode-discount-token",
          JSON.stringify(response.data.response)
        )
      }
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const discount = createAsyncThunk(
  "discount/discount",
  async thunkAPI => {
    try {
      const response = await axios.get(`${url}/discount`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        }
      })
      if (response) {
        localStorage.setItem(
          "deonicode-discount-token",
          JSON.stringify(response.data)
        )
      }
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateDiscount = createAsyncThunk(
  "discount/updateDiscount",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${url}/update-discount/${data.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      if (response) {
        localStorage.setItem(
          "deonicode-discount-token",
          JSON.stringify(response.data.response[0])
        )
      }
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteDiscount = createAsyncThunk(
  "discount/deleteDiscount",
  async (data, thunkAPI) => {
    try {
      const response = await axios.delete(`${url}/delete-discount/${data.id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        }
      })
      if (response) {
        localStorage.removeItem("deonicode-discount-token")
      }
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const priceRange = createAsyncThunk(
  "discount/priceRange",
  async thunkAPI => {
    try {
      const response = await axios.get(`${url}/range`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const createPriceRange = createAsyncThunk(
  "discount/createPriceRange",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(`${url}/update-range/${data.id}`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deonicodePercentage = createAsyncThunk(
  "discount/deonicodePercentage",
  async thunkAPI => {
    try {
      const response = await axios.get(`${url}/percentage`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updatePercentage = createAsyncThunk(
  "discount/updatePercentage",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${url}/update-percentage/${data.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const affiliatePercentage = createAsyncThunk(
  "discount/affiliatePercentage",
  async thunkAPI => {
    try {
      const response = await axios.get(`${url}/affiliate-percentage`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        }
      })
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateAffiliatePercentage = createAsyncThunk(
  "discount/updateAffiliatePercentage",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${url}/update-affiliate-percentage/${data.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      return response.data
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const authSlice = createSlice({
  name: "discount",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(createDiscount.fulfilled, (state, action) => {
        state.discount = action.payload.response
      })
      .addCase(discount.fulfilled, (state, action) => {
        state.discount = action.payload
      })
      .addCase(updateDiscount.fulfilled, (state, action) => {
        state.discount = action.payload.updated
      })
      //
      .addCase(createPriceRange.fulfilled, (state, action) => {
        state.priceRange = action.payload.response
      })
      .addCase(priceRange.fulfilled, (state, action) => {
        state.priceRange = action.payload
      })
      //
      .addCase(updatePercentage.fulfilled, (state, action) => {
        state.deonicodePercentage = action.payload.response
      })
      .addCase(deonicodePercentage.fulfilled, (state, action) => {
        state.deonicodePercentage = action.payload
      })
      // 
      .addCase(updateAffiliatePercentage.fulfilled, (state, action) => {
        state.affiliatePercentage = action.payload.response
      })
      .addCase(affiliatePercentage.fulfilled, (state, action) => {
        state.affiliatePercentage = action.payload
      })
  },
})

export const { logout } = authSlice.actions

export default authSlice.reducer
