import React, { useState, useEffect } from "react"
import { Col, Modal, ModalBody, ModalHeader, Row, Button } from "reactstrap"
import { Tooltip } from "react-tooltip"
import Select from "react-select"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { editCourse } from "helpers/helpers/courses"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"
import { Editor } from "primereact/editor"
const EditCourseComponent = ({
  modal_xlarge,
  tog_xlarge,
  action,
  courseEdit,
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const [courseTitle, setCourseTitle] = useState("")
  const [courseCategory, setCourseCategory] = useState("")
  const [introVideoUrl, setIntroVideoUrl] = useState("")
  const [courseImage, setCourseImage] = useState(null)
  const [coursePrice, setCoursePrice] = useState(0)
  const [courseLevel, setCourseLevel] = useState("")
  const [courseDuration, setCourseDuration] = useState("")
  const [courseLanguage, setCourseLanguage] = useState("")
  const [courseTag, setCourseTag] = useState("")
  const [courseTags, setCourseTags] = useState([])
  const [courseDesc, setCourseDesc] = useState();
  const [mentorship, setMentorship] = useState();

  const optionGroup = [
    {
      label: "Categories",
      options: [
        { label: "Front-End Development", value: "Front-End Development" },
        { label: "Backend Development", value: "Backend Development" },
        { label: "Mobile Development", value: "Mobile Development" },
        { label: "Blockchain Development", value: "Blockchain Development" },
      ],
    },
  ]

  const courseLevelGroup = [
    {
      label: "Course Level",
      options: [
        { label: "beginner", value: "Beginner" },
        { label: "intermediate", value: "Intermediate" },
        { label: "professional", value: "Professional" },
        { label: "expert", value: "Expert" },
      ],
    },
  ]

  const setCourseTagHangler = () => {
    if (courseTag) {
      setCourseTags([...courseTags, courseTag])
      setCourseTag("")
      document.getElementById("tag-text-field").value = ""
    } else {
      toast.error("Tag is required")
    }
  }

  const removeTagHandler = item => {
    const newTagArray = courseTags.filter(tag => tag !== item)
    setCourseTags(newTagArray)
  }

  useEffect(() => {
    if (courseEdit && courseEdit !== null) {
      setCourseTitle(courseEdit?.title)
      setCourseCategory(courseEdit?.category)
      setIntroVideoUrl(courseEdit?.introVideoUrl)
      setCoursePrice(courseEdit?.price)
      setCourseLevel(courseEdit?.courseLevel)
      setCourseDuration(courseEdit?.duration)
      setCourseDuration(courseEdit?.mentorship)
      setCourseLanguage(courseEdit?.language)
      setCourseTags(courseEdit?.tags)
      setCourseDesc(courseEdit?.desc)
    } else {
      return
    }
  }, [courseEdit])

  const handleCourseUpdate = async () => {
    let formData = new FormData()
    formData.append("id", id)
    formData.append("title", courseTitle)
    formData.append("desc", JSON.stringify(courseDesc))
    formData.append("category", courseCategory)
    formData.append("coverImage", courseImage)
    formData.append("price", coursePrice)
    formData.append("courseLevel", courseLevel)
    formData.append("mentorship", mentorship)
    formData.append("duration", courseDuration)
    formData.append("language", courseLanguage)
    formData.append("introVideoUrl", introVideoUrl)
    for (let i = 0; i < courseTags.length; i++) {
      formData.append("tags", courseTags[i])
    }

    const data = {
      id,
      formData,
    }

    const response = await editCourse(data, dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    } else {
      toast.success(response.message.payload.message)
      tog_xlarge()
      window.location.reload()
    }
  }

  return (
    <div>
      <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="xl">
        <ModalHeader className="mt-0" toggle={tog_xlarge}>
          {action} Course
        </ModalHeader>
        <ModalBody>
          <div className="p-3">
            <input
              type="text"
              value={courseTitle}
              placeholder="Course Title"
              className="form-control"
              onChange={e => setCourseTitle(e.target.value)}
            />
            <br />
            <p className="h6">Category:</p>
            <Select
              onChange={e => setCourseCategory(e.value)}
              options={optionGroup}
              classNamePrefix="select2-selection"
            />
            <br />
            <p className="h6">
              Please provide a link to an introductory video. You can always
              provide one later, but your course will not be approved until you
              do.
            </p>
            <input
              type="text"
              value={introVideoUrl}
              placeholder="Introductory video url"
              className="form-control"
              onChange={e => setIntroVideoUrl(e.target.value)}
            />
            <br />
            <p className="h6">Mentorship link</p> Don't know calendly? <a href="https://calendly.com" target="_blank">Click here</a>
            <input
              type="text"
              value={mentorship}
              placeholder="Please provide a link from calendly"
              className="form-control"
              onChange={e => setMentorship(e.target.value)}
            />
            <br />
            <p className="h6">Thumbnail:</p>
            <input
              type="file"
              accept="image/*"
              placeholder="Cover image *"
              className="form-control"
              onChange={e => setCourseImage(e.target.files[0])}
            />
            <br />
            <p className="h6">Price</p>
            <input
              type="number"
              value={coursePrice}
              placeholder="Price *"
              className="form-control"
              onChange={e => setCoursePrice(e.target.value)}
            />
            <br />
            <p className="h6">Course Level:</p>
            <Select
              // value={courseLevel}
              onChange={e => setCourseLevel(e.value)}
              options={courseLevelGroup}
              classNamePrefix="select2-selection"
            />
            <br />
            <p className="h6">Course duration:</p>
            <input
              type="number"
              value={courseDuration}
              placeholder="Course duration in hours"
              className="form-control"
              onChange={e => setCourseDuration(e.target.value)}
            />
            <br />
            <p className="h6">Language:</p>
            <input
              type="text"
              value={courseLanguage}
              placeholder="Language"
              className="form-control"
              onChange={e => setCourseLanguage(e.target.value)}
            />
            <br />
            <div>
              <ul>
                {courseTags?.length > 0
                  ? courseTags.map((item, index) => (
                      <li key={index}>
                        {item}{" "}
                        <a
                          href="#"
                          className="m-2 text-danger"
                          onClick={() => removeTagHandler(item)}
                        >
                          remove
                        </a>
                      </li>
                    ))
                  : ""}
              </ul>
              <Row>
                <Col>
                  <input
                    type="text"
                    id="tag-text-field"
                    placeholder="Add tags"
                    className="form-control"
                    onChange={e => setCourseTag(e.target.value)}
                  />
                </Col>

                <a
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={`
                    Add keywords or terms associated with this course
                    to help users easily find your course. e.g Frontend, Blockchain, Tailwind CSS ...
                  `}
                  style={{
                    width: "20px",
                  }}
                  className="p-0 mt-2 rounded"
                >
                  <i className="fas fa-question-circle m-0 p-1 "></i>
                </a>
                <Tooltip id="my-tooltip" />
                <Col>
                  <Button color="primary" onClick={setCourseTagHangler}>
                    Add
                  </Button>
                </Col>
              </Row>
            </div>
            <br />
            <p className="h6">Course description and expected outcomes</p>
            <Editor
              value={courseDesc}
              onTextChange={e => setCourseDesc(e.htmlValue)}
              style={{ height: "320px" }}
            />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {loading ? (
                <Button color="primary">
                  <ButtonLoader size={30} color1={"white"} color2={"white"} />
                </Button>
              ) : (
                <Button color="primary" onClick={handleCourseUpdate}>
                  {action}
                </Button>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default EditCourseComponent
