import React, { useState, useEffect, useContext } from "react"
import "./Mentorship.css"
import { Card, CardBody } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Questions from "components/mentorship/Questions"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { AuthContext } from "context/authContext/authContext"
import { mentorshipUsers } from "helpers/helpers/mentorship"
import { myCourses } from "helpers/helpers/courses"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const Mentorship = () => {
  const dispatch = useDispatch()
  const { userId } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [courses, setCourses] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedCourseId, setSelectedCourseId] = useState("")
  const users = useSelector(state => state.mentorship.users)

  const courseListing = [
    {
      label: "Course Level",
      options:
        courses &&
        courses.map((item, index) => ({ label: item.title, value: item._id })),
    },
  ]

  const getCourses = async () => {
    const data = {
      id: userId,
    }
    const response = await myCourses(data, dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    } else {
      setCourses(response.message.payload.courses)
    }
  }

  const getMentoredUsers = async () => {
    const data = {
      id: userId,
      courseId: selectedCourseId,
    }
    const response = await mentorshipUsers(data, dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    }
  }

  useEffect(() => {
    if (selectedCourseId && selectedCourseId !== "") {
      getMentoredUsers()
    }
  }, [selectedCourseId])

  useEffect(() => {
    if (userId !== "") {
      getCourses()
    }
  }, [userId])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid"></div>
        <Breadcrumbs title="Deonicode" breadcrumbItem="Mentorhip" />
        <Select
          onChange={e => setSelectedCourseId(e.value)}
          options={courseListing}
          classNamePrefix="select2-selection"
        />
        <br />
        <Card>
          <CardBody>
            <div className="chat">
              <div className="mentees">
                {loading ? (
                  <ButtonLoader size={40} color1={"black"} color2={"black"} />
                ) : users && users.length > 0 ? (
                  users.map((user, index) => (
                    <div
                      className="mentee"
                      key={index}
                      onClick={() => setSelectedUser(user)}
                    >
                      <img
                        src={
                          user.avatar.doc ||
                          "https://xsgames.co/randomusers/avatar.php?g=pixel"
                        }
                        height={55}
                        width={55}
                        className="mentee-img"
                      />
                      <div>
                        {user.username}
                        {/* <span className="badge rounded-pill bg-primary float-end">
                          9
                        </span> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="p-2 mt-4">
                    <p className="fw-bold">
                      Select Course to Load Conversations
                    </p>
                  </div>
                )}
              </div>
              <Questions user={selectedUser} courseId={selectedCourseId} />
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default Mentorship
