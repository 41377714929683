import React, { useContext, useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Row, Col, Button } from "reactstrap"
import { Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AuthContext } from "context/authContext/authContext"
import WithdrawalModal from "components/WithdrawalModal"
//
import moment from "moment"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { requestByUser, allRequests } from "helpers/helpers/withdrawals"
import { conversionRate } from "helpers/helpers/transactions"
// import { getSingleTeacher } from "helpers/helpers/Teachers"
import { getAllTeachers } from "helpers/helpers/Teachers"
import { getAllUsers } from "helpers/helpers/users"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const Withdrawals = () => {
  const { userId, role } = useContext(AuthContext)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [modal_approve, setmodal_approve] = useState(false)
  const [statusItem, setStatusItem] = useState("")
  const [withdrawals, setAllwithdrawals] = useState([])
  const [currencyConversion, setCurrencyConversion] = useState("")

  const formatMoney = amount => {
    let dollarUSLocale = Intl.NumberFormat("en-US")
    return dollarUSLocale.format(amount)
  }

  const alltutors = useSelector(state => state.teachers.teachers?.users)
  const allusers = useSelector(state => state.users.users?.users)
  const allStateWithdrawals = useSelector(
    state => state.withdrawals.withdrawals
  )

  const tog_approve = () => {
    setmodal_approve(!modal_approve)
  }

  const approveWithdrawalHandler = item => {
    setStatusItem(item)
    tog_approve()
  }

  const allWithdrawals = async () => {
    if (role === "admin") {
      const response = await allRequests(dispatch, setLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
      }
    }
    if (role === "instructor") {
      const data = { userId }
      const response = await requestByUser(data, dispatch, setLoading)
      if (response.status === "error") {
        toast.error(response.res.payload)
      }
    }
  }

  const allTeachers = async () => {
    const response = await getAllTeachers(dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    } else {
      toast.success(response.message.payload.message)
    }
  }

  const allUsers = async () => {
    const response = await getAllUsers(dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    } else {
      toast.success(response.message.payload.message)
    }
  }

  const getConversionRate = async () => {
    const response = await conversionRate(dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    } else {
      const result = JSON.parse(response.message.payload.data)
      setCurrencyConversion(result.result)
    }
  }

  useEffect(() => {
    allUsers()
    allTeachers()
    getConversionRate()
  }, [])

  useEffect(() => {
    setAllwithdrawals(allStateWithdrawals)
  }, [allStateWithdrawals])

  useEffect(() => {
    allWithdrawals()
  }, [userId])

  function filterFunction(textInput) {
    // Declare variables
    var input, filter, table, tr, td, i, txtValue
    input = textInput
    filter = input.value.toUpperCase()
    table = document.getElementById("widthdrawTable")
    tr = table.getElementsByTagName("tr")

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[3]
      if (td) {
        txtValue = td.textContent || td.innerText
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = ""
        } else {
          tr[i].style.display = "none"
        }
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Deonicode" breadcrumbItem="Withdrawals" />
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <CardTitle className="mb-4">Withdrawals</CardTitle>
                </Col>
                <Col xl={2}>
                  <CardTitle className="mb-4">
                    <input
                      type="text"
                      placeholder="search date"
                      className="form-control"
                      onChange={e => filterFunction(e.target)}
                    />
                  </CardTitle>
                </Col>
              </Row>
              <div className="table-responsive">
                <table
                  className="table align-middle table-nowrap mb-0"
                  id="widthdrawTable"
                >
                  <thead className="table-light">
                    <tr>
                      <th className="align-middle">ID</th>
                      <th className="align-middle">Name</th>
                      <th className="align-middle">Amount in USD</th>
                      <th className="align-middle">Amount in XAF</th>
                      {/* <th className="align-middle">Deonicode Percentage</th> */}
                      {/* <th className="align-middle">Actual Amount</th> */}
                      <th className="align-middle">Status</th>
                      <th className="align-middle">Date</th>
                      {role === "admin" ? (
                        <th className="align-middle">Action</th>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr className="text-center">
                        <td colSpan={9}>
                          <ButtonLoader
                            size={40}
                            color1={"black"}
                            color2={"black"}
                          />
                        </td>
                      </tr>
                    ) : withdrawals?.length > 0 ? (
                      withdrawals.map((transaction, key) => {
                        const searchUserById = (users, userId) => {
                          return users?.find(user => user._id === userId)
                        }
                        const user =
                          searchUserById(allusers, transaction.userId) ||
                          searchUserById(alltutors, transaction.userId)

                        return (
                          <tr key={"_tr_" + key}>
                            <td>
                              <div className="font-size-16">{key + 1}</div>
                            </td>
                            <td>
                              <Link to="#" className="text-body fw-bold">
                                {user?.username}
                              </Link>
                            </td>
                            <td>
                              {formatMoney(Math.round(transaction?.amount))} USD
                            </td>
                            <td>
                              {formatMoney(
                                Math.round(
                                  transaction?.amount * currencyConversion
                                )
                              )}{" "}
                              XAF
                            </td>
                            {/* <td>
                              {transaction?.deonicdePerc}% - (
                              {formatMoney(
                                Math.round(
                                  transaction?.deonicdeAmount *
                                    currencyConversion
                                )
                              )}
                              )
                            </td> */}
                            {/* <td>
                              {formatMoney(
                                Math.round(
                                  transaction?.actualAmount * currencyConversion
                                )
                              )}{" "}
                              XAF
                            </td> */}
                            <td
                              className={
                                transaction.status === "pending"
                                  ? "text-warning"
                                  : transaction.status === "approved"
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {transaction?.status}
                            </td>
                            <td>
                              {moment(transaction.createdAt).format(
                                "DD-MM-YYYY HH:mm"
                              )}
                            </td>
                            {role === "admin" ? (
                              <td>
                                <Button
                                  type="button"
                                  color="success"
                                  size="sm"
                                  className="waves-effect waves-light"
                                  onClick={() =>
                                    approveWithdrawalHandler({
                                      type: "approveWithdrawal",
                                      text:
                                        "Update request status of ($" +
                                        transaction.amount +
                                        ") by " +
                                        user?.username,
                                      id: transaction._id,
                                    })
                                  }
                                >
                                  Edit
                                </Button>
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        )
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan={6}>No Requets Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </div>
        <WithdrawalModal
          modal_xlarge={modal_approve}
          tog_xlarge={tog_approve}
          action={statusItem}
        />
      </div>
    </React.Fragment>
  )
}

export default Withdrawals
