import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import { toast } from "react-toastify"
import { useParams } from "react-router-dom"
import user1 from "../../assets/images/users/user-4.jpg"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { useDispatch } from "react-redux"
import { getSingleUser } from "helpers/helpers/users"
import moment from "moment"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const UserDetails = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({})
  const { id } = useParams()

  const userDetils = async () => {
    const data = {
      id,
    }
    const response = await getSingleUser(data, dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    } else {
      setUser(response.message.payload.user)
    }
  }

  useEffect(() => {
    userDetils()
  }, [id])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            // maintitle="Deonicode"
            title="Deonicode"
            breadcrumbItem="User Details"
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {loading ? (
                    <ButtonLoader size={40} color1={"black"} color2={"black"} />
                  ) : (
                    <>
                      <Row>
                        <Col xl={3} lg={3} sm={12}>
                          <img
                            className="rounded"
                            src={user?.avatar?.doc}
                            alt={user?.username}
                            width="100%"
                            height="300"
                          />
                        </Col>
                        <Col>
                          <div>
                            <span className="fw-bold">Full Name: </span>
                            {user?.username}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Email: </span>
                            {user?.email}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Country: </span>
                            {user?.country ? user?.country : "No Country Found"}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Created account: </span>
                            {moment(user?.createdAt).format("DD-MM-YYYY HH:mm")}
                          </div>
                          <div className="mt-2">
                            <span className="fw-bold">Account status: </span>
                            {user.status === "active" ? (
                              <span className="p-2 text-success">Active</span>
                            ) : user.status === "pending" ? (
                              <span className="p-2 text-warning">Pending</span>
                            ) : user.status === "suspended" ? (
                              <span className="p-2 text-dark">Suspended</span>
                            ) : (
                              <span className="p-2 text-danger">
                                Deactivated
                              </span>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="mt-4">
                            <span className="fw-bold">Bio information: </span>
                            <div className="mt-2">
                              {user?.bio ? user?.bio : "No Bio Data Found"}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UserDetails
