import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { toast } from "react-toastify"
import moment from "moment"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { useDispatch, useSelector } from "react-redux"
import { allSubscribers } from "helpers/helpers/subscribers"
import ButtonLoader from "components/Loader/ButtonLoader"

const Category = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [subscribers, setSubscribers] = useState([])
  const stateSubscribers = useSelector(state => state.subscriber.subscribers)

  const getAllSubscribers = async () => {
    const response = await allSubscribers(dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    } else {
      toast.success(response.message.payload.message)
    }
  }

  useEffect(() => {
    getAllSubscribers()
  }, [])

  useEffect(() => {
    setSubscribers(stateSubscribers)
  }, [stateSubscribers])

  function filterFunction(textInput) {
    // Declare variables
    var input, filter, table, tr, td, i, txtValue
    input = textInput
    filter = input.value.toUpperCase()
    table = document.getElementById("subscriberTable")
    tr = table.getElementsByTagName("tr")

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[1]
      if (td) {
        txtValue = td.textContent || td.innerText
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = ""
        } else {
          tr[i].style.display = "none"
        }
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            action="category"
            title="Deonicode"
            breadcrumbItem="Subscribers"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle className="mb-4">
                        List of Subscribers
                      </CardTitle>
                    </Col>
                    <Col xl={2}>
                      <CardTitle className="mb-4">
                        <input
                          type="text"
                          placeholder="search subscribers"
                          className="form-control"
                          onChange={e => filterFunction(e.target)}
                        />
                      </CardTitle>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <table
                      className="table align-middle table-nowrap mb-0"
                      id="subscriberTable"
                    >
                      <thead className="table-light">
                        <tr>
                          <th className="align-middle">ID</th>
                          <th className="align-middle">Name</th>
                          <th className="align-middle">Email</th>
                          <th className="align-middle">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan={7}>
                              <ButtonLoader
                                size={40}
                                color1={"black"}
                                color2={"black"}
                              />
                            </td>
                          </tr>
                        ) : subscribers?.length > 0 ? (
                          subscribers?.map((subscriber, key) => (
                            <tr
                              key={"_subscriber_" + key}
                              style={{ width: "100%" }}
                            >
                              <td>
                                <div className="font-size-16">{key + 1}</div>
                              </td>
                              <td>{subscriber.username}</td>
                              <td>{subscriber.email}</td>
                              <td>
                                {moment(subscriber.createdAt).format(
                                  "DD-MM-YYYY HH:mm"
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} className="text-center">
                              No Subscriber Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Category
