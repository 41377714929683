import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import moment from "moment"
import StatusModal from "components/StatusModal"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

// redux
import { useDispatch } from "react-redux"
// actions
import { getAllUsers } from "helpers/helpers/users"
// Loader Component
import ButtonLoader from "components/Loader/ButtonLoader"

const Users = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [modal_status, setstatus_approve] = useState(false)
  const [statusItem, setStatusItem] = useState("")
  const [allusers, setAllUsers] = useState([])

  const tog_approve = () => {
    setstatus_approve(!modal_status)
  }

  const statusUpdateHandler = item => {
    setStatusItem(item)
    tog_approve()
  }

  const allUsers = async () => {
    const response = await getAllUsers(dispatch, setLoading)
    if (response.status === "error") {
      toast.error(response.res.payload)
    } else {
      // const edited = response.message.payload.users.map(o => ({ ...o }))
      setAllUsers(response.message.payload.users)
      toast.success(response.message.payload.message)
    }
  }

  useEffect(() => {
    allUsers()
  }, [])

  function filterFunction(textInput) {
    // Declare variables
    var input, filter, table, tr, td, i, txtValue
    input = textInput
    filter = input.value.toUpperCase()
    table = document.getElementById("userTable")
    tr = table.getElementsByTagName("tr")

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[1]
      if (td) {
        txtValue = td.textContent || td.innerText
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = ""
        } else {
          tr[i].style.display = "none"
        }
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            // maintitle="Deonicode"
            title="Deonicode"
            breadcrumbItem="Users"
          />

          <Row>
            <Col className="col-12">
              <Card>
                {/* <CardBody>
                  {loading ? (
                    "Loading..."
                  ) : (
                    <MDBDataTable
                      responsive
                      striped
                      bordered
                      data={{ columns, rows }}
                    />
                  )}
                </CardBody> */}
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle className="mb-4">Users</CardTitle>
                    </Col>
                    <Col xl={2}>
                      <CardTitle className="mb-4">
                        <input
                          type="text"
                          placeholder="search username"
                          className="form-control"
                          onChange={e => filterFunction(e.target)}
                        />
                      </CardTitle>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <table
                      className="table align-middle table-nowrap mb-0"
                      id="userTable"
                    >
                      <thead className="table-light">
                        <tr>
                          <th className="align-middle">ID</th>
                          <th className="align-middle">Username</th>
                          <th className="align-middle">Email</th>
                          <th className="align-middle">Courses</th>
                          <th className="align-middle">Status</th>
                          <th className="align-middle">Country</th>
                          <th className="align-middle">Date</th>
                          <th className="align-middle">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan={8}>
                              <ButtonLoader
                                size={40}
                                color1={"black"}
                                color2={"black"}
                              />
                            </td>
                          </tr>
                        ) : allusers.length > 0 ? (
                          allusers.map((user, key) => (
                            <tr key={"_user_" + key} style={{ width: "100%" }}>
                              <td>
                                <div className="font-size-16">{key + 1}</div>
                              </td>
                              <td>{user.username}</td>
                              <td>{user.email}</td>
                              <td>{user.purchasedCourses.length}</td>
                              <td>
                                {user.status === "active" ? (
                                  <span className="p-1 text-light rounded bg-success fw-bold">
                                    Active
                                  </span>
                                ) : user.status === "pending" ? (
                                  <span className="p-1 text-light rounded bg-warning fw-bold">
                                    Pending
                                  </span>
                                ) : user.status === "suspended" ? (
                                  <span className="p-1 text-light rounded bg-dark fw-bold">
                                    Suspended
                                  </span>
                                ) : (
                                  <span className="p-1 text-light rounded bg-danger fw-bold">
                                    Deactivated
                                  </span>
                                )}
                              </td>
                              <td>
                                {user.country
                                  ? user.country
                                  : "No Country Found"}
                              </td>
                              <td>
                                {moment(user.createdAt).format(
                                  "DD-MM-YYYY HH:mm"
                                )}
                              </td>
                              <td>
                                <Link to={`/user-details/${user._id}`}>
                                  <Button color="primary m-1" size="sm">
                                    View
                                  </Button>
                                </Link>
                                <Button
                                  size="sm"
                                  color="success"
                                  onClick={() =>
                                    statusUpdateHandler({
                                      type: "user",
                                      text: user.username,
                                      id: user._id,
                                    })
                                  }
                                >
                                  Edit
                                </Button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} className="text-center">
                              No User Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <StatusModal
          modal_xlarge={modal_status}
          tog_xlarge={tog_approve}
          action={statusItem}
        />
      </div>
    </React.Fragment>
  )
}

export default Users
