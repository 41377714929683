import {
  createQuestion,
  getMentorshipUsers,
  getMentorshipQuestions,
} from "store/reduxToolkit/reducers/mentorshipReducer"

export const mentorshipUsers = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(getMentorshipUsers(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const mentorshipQuestions = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(getMentorshipQuestions(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}

export const postQuestionResponse = async (data, dispatch, setLoading) => {
  setLoading(true)
  try {
    const response = await dispatch(createQuestion(data)).then(res => {
      if (res.meta.requestStatus === "rejected") {
        return { status: "error", message: "response error", res }
      } else {
        return { status: "success", message: res }
      }
    })
    setLoading(false)
    return response
  } catch (error) {
    setLoading(false)
    return { status: "error", message: "catch error", error }
  }
}
