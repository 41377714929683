import { configureStore } from "@reduxjs/toolkit"
import Layout from "../layout/reducer"
import Profile from "../auth/profile/reducer"
import authReducer from "./reducers/authReducer"
import usersReducer from "./reducers/usersReducer"
import teachersReducers from "./reducers/teachersReducers"
import courseReducer from "./reducers/courseReducer"
import chapterReducer from "./reducers/chapterReducer"
import lessonReducer from "./reducers/lessonReducer"
import transactionReducer from "./reducers/transactionReducer"
import mentorshipReducer from "./reducers/mentorshipReducer"
import announcementReducer from "./reducers/announcementReducer"
import withdrawalReducer from "./reducers/withdrawalReducer"
import mailsReducer from "./reducers/emailReducer"
import discountReducer from "./reducers/settingsReducer"
import categoryReducer from "./reducers/categoryReducer"
import subscriberReducer from "./reducers/subscriberReducer"

const store = configureStore({
  reducer: {
    Profile,
    layout: Layout,
    auth: authReducer,
    users: usersReducer,
    mails: mailsReducer,
    lessons: lessonReducer,
    courses: courseReducer,
    chapters: chapterReducer,
    discount: discountReducer,
    category: categoryReducer,
    teachers: teachersReducers,
    mentorship: mentorshipReducer,
    subscriber: subscriberReducer,
    withdrawals: withdrawalReducer,
    transaction: transactionReducer,
    announcement: announcementReducer,
  },
})
export default store
