import React, { useState } from "react"
import { Col, Modal, ModalBody, ModalHeader, Row, Button } from "reactstrap"

const CreateAffiliate = ({ modal_xlarge, tog_xlarge, action }) => {
  const [link, setLink] = useState("https://deonicode.net/wioqoewh")

  return (
    <div>
      <Modal isOpen={modal_xlarge} toggle={tog_xlarge} size="sl">
        <ModalHeader className="mt-0" toggle={tog_xlarge}>
          {action} Affiliate
        </ModalHeader>
        <ModalBody>
          <div className="p-3">
            <input type="text" placeholder="Name" className="form-control" />
            <br />
            <input type="email" placeholder="Email" className="form-control" />
            <br />
            <div>
              <Row>
                <Col md="9">
                  <input
                    type="text"
                    placeholder="Name"
                    value={link}
                    className="form-control"
                  />
                </Col>
                <Col>
                  <Button>copy</Button>
                </Col>
              </Row>
            </div>
            <br />
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={true}
                id="flexCheckDefault"
              />
              <label className="form-check-label" for="flexCheckDefault">
                Affiliate Active
              </label>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button color="primary">{action}</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default CreateAffiliate
