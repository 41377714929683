import React, { useState } from "react"
import { Card, CardBody, CardTitle, Row, Col, Button } from "reactstrap"
import { Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import CreateAffiliate from "components/Affiliates/CreateAffiliate"
const Affiliates = () => {
  const transactions = [
    {
      id: "customCheck2",
      orderId: "#SK2540",
      billingName: "Neal Matthews",
      Date: "07 Oct, 2019",
      total: "$400",
      badgeClass: "success",
      paymentStatus: "Paid",
      methodIcon: "fa-cc-mastercard",
      paymentMethod: "Mastercard",
      link: "#",
    },
    {
      id: "customCheck3",
      orderId: "#SK2541",
      billingName: "Jamal Burnett",
      Date: "07 Oct, 2019",
      total: "$380",
      badgeClass: "danger",
      paymentStatus: "Chargeback",
      methodIcon: "fa-cc-visa",
      paymentMethod: "Visa",
      link: "#",
    },
    {
      id: "customCheck4",
      orderId: "#SK2542",
      billingName: "Juan Mitchell",
      Date: "06 Oct, 2019",
      total: "$384",
      badgeClass: "success",
      paymentStatus: "Paid",
      methodIcon: "fa-cc-paypal",
      paymentMethod: "Paypal",
      link: "#",
    },
    {
      id: "customCheck5",
      orderId: "#SK2543",
      billingName: "Barry Dick",
      Date: "05 Oct, 2019",
      total: "$412",
      badgeClass: "success",
      paymentStatus: "Paid",
      methodIcon: "fa-cc-mastercard",
      paymentMethod: "Mastercard",
      link: "#",
    },
    {
      id: "customCheck6",
      orderId: "#SK2544",
      billingName: "Ronald Taylor",
      Date: "04 Oct, 2019",
      total: "$404",
      badgeClass: "warning",
      paymentStatus: "Refund",
      methodIcon: "fa-cc-visa",
      paymentMethod: "Visa",
      link: "#",
    },
    {
      id: "customCheck7",
      orderId: "#SK2545",
      billingName: "Jacob Hunter",
      Date: "04 Oct, 2019",
      total: "$392",
      badgeClass: "success",
      paymentStatus: "Paid",
      methodIcon: "fa-cc-paypal",
      paymentMethod: "Paypal",
      link: "#",
    },
  ]
  const [affiliateAction, setAffiliateAction] = useState("")
  const [modal_affiliate, setmodal_affiliate] = useState(false)

  const tog_affiliate = () => {
    setAffiliateAction("Edit")
    setmodal_affiliate(!modal_affiliate)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Deonicode"
            action="affiliate"
            btnShow={true}
            btnText="Create Affiliate"
            breadcrumbItem="Affiliates"
          />
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <CardTitle className="mb-4">Affiliates</CardTitle>
                </Col>
                <Col xl={2}>
                  <CardTitle className="mb-4">
                    <input
                      type="text"
                      name=""
                      placeholder="search"
                      id=""
                      className="form-control"
                    />
                  </CardTitle>
                </Col>
              </Row>
              <div className="table-responsive">
                <table className="table align-middle table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="align-middle">Name</th>
                      <th className="align-middle">Sales</th>
                      <th className="align-middle">Income Made</th>

                      <th className="align-middle">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((transaction, key) => (
                      <tr key={"_tr_" + key}>
                        <td>
                          <Link to="#" className="text-body fw-bold">
                            {transaction.billingName}
                          </Link>
                        </td>

                        <td>0</td>
                        <td>0</td>

                        <td>
                          <Button
                            onClick={tog_affiliate}
                            type="button"
                            color="primary"
                            size="sm"
                            className="waves-effect waves-light"
                          >
                            View & Edit
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <CreateAffiliate
        action={affiliateAction}
        modal_xlarge={modal_affiliate}
        tog_xlarge={tog_affiliate}
      />
    </React.Fragment>
  )
}

export default Affiliates
